import React, { useEffect, useState } from 'react'
import { Modal, OverlayTrigger, Popover } from 'react-bootstrap'
import Select from 'react-select'
import { UserAuth } from '../auth/Auth';
import { PrivateAxiosFile, url } from '../../environment/AxiosInstance';

import { SuccessMessage } from '../../environment/ToastMessage';
import Loader from '../../loader/Loader';


function EditHelpTicket({ showEditHelpDeskModal, handleCloseEditHelpDeskModal, showEditHelpData, GetPending }) {
    const { User, userDetails, Logout } = UserAuth();
    const [ticketAttachments, setTicketAttachments] = useState([]);
    const [preview, setPreview] = useState('');
    const handleTicketAttachment = (event) => {
        const files = Array.from(event.target.files);
        setTicketAttachments((prevAttachments) => [...prevAttachments, ...files]);
        setHelpValue({ ...helpValue, file: event.target.files[0] })
        // const fileUrl = URL.createObjectURL(event.target.files[0]);
        // setPreview(fileUrl);
    }
    const [loading, setLoading] = useState(false)

    const [helpValue, setHelpValue] = useState({
        "assigned_by": userDetails.id,
        "assign_to": "",
        "subject": "",
        "description": "",
        "cc": "",
        "file": ""
    })

    const [multiUser, setMultiUser] = useState([])
    useEffect(() => {
        if (showEditHelpData) {
            setHelpValue({
                ...helpValue,
                "assign_to": showEditHelpData.assign_to,
                "subject": showEditHelpData.subject,
                "description": showEditHelpData.description,
            })
            setPreview(showEditHelpData.file)
            if (showEditHelpData.users.length > 0) {
                const user = showEditHelpData.users.map(data => data.user_id) || [];
                const selectUsers = User.filter(data => user.includes(data.id));
                setMultiUser(selectUsers)

            }
        }
    }, [showEditHelpData])

    const onUpdateSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData();
        if (helpValue.file == "") {
            formData.append("assign_to", helpValue.assign_to)
            formData.append("subject", helpValue.subject)
            formData.append("description", helpValue.description)
            formData.append("cc", helpValue.cc)
        } else {
            formData.append("assign_to", helpValue.assign_to)
            formData.append("subject", helpValue.subject)
            formData.append("description", helpValue.description)
            formData.append("cc", helpValue.cc)
            formData.append("file", helpValue.file)
        }
        PrivateAxiosFile.put(`ticket/update/${showEditHelpData.id}`, formData)
            .then((res) => {
                GetPending();
                SuccessMessage(res.data.msg)
                handleCloseEditHelpDeskModal();
            }).catch((err) => {
                if (err.response.status == 401) {
                    Logout();
                }
            })

    }

    const handleDownload = () => {
        const fileExtension = preview.split('.').pop();
        const fileName = `ticket.${fileExtension}`;
        const link = document.createElement('a');
        link.href = url + preview;
        link.download = fileName;
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const attachmentListAction = (
        <Popover id="statusChange" className="action-wrap">
            <div className="action-list">
                <div className="action-list-item" onClick={handleDownload}>
                    <i className="fas fa-download me-2"></i>
                    <span>Download</span>
                </div>
                {/* <div className="action-list-item">
                    <i className="fas fa-trash-alt text-exp-red me-2"></i>
                    <span>Delete</span>
                </div> */}
            </div>
        </Popover>
    );


    return (
        <>
            {loading ? <Loader /> : ""}
            <Modal id="edit-HelpDesk-modal" show={showEditHelpDeskModal} onHide={handleCloseEditHelpDeskModal} backdrop="static" centered size="lg">
                <Modal.Header closeButton className="gth-blue-light-bg">
                    <Modal.Title className="gth-modal-title">Edit Ticket No: <span></span></Modal.Title>
                </Modal.Header>
                <form onSubmit={onUpdateSubmit}>
                    <Modal.Body className="pb-0">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <label className="form-label">Doer</label>
                                    <div className='custom-select-wrap'>
                                        <Select
                                            name='selectDoer'
                                            required
                                            options={User}
                                            value={helpValue.assign_to && User.length > 0 ? User.find(data => data.id == helpValue.assign_to) : ""}
                                            getOptionLabel={(option) => option.name}
                                            getOptionValue={(option) => option.id}
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#ddddff',
                                                    primary: '#6161ff',
                                                },
                                            })}
                                            onChange={(data) => setHelpValue({ ...helpValue, assign_to: data.id })}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-lg-6">
                                <div className="form-group">
                                    <label className="form-label">Priority</label>
                                    <div className='custom-select-wrap'>
                                        <Select
                                            name='selectDoer'
                                            options={TaskPriority}
                                            required
                                            getOptionLabel={(option) => option.title}
                                            getOptionValue={(option) => option.id}
                                            isMulti={true}
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#ddddff',
                                                    primary: '#6161ff',
                                                },
                                            })}
                                            onChange={(data)=>setHelpValue({...helpValue,assign_to:data.id})}
                                        />
                                    </div>
                                </div>
                            </div> */}
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <label className="form-label">CC</label>
                                    <div className='custom-select-wrap'>
                                        <Select
                                            name='selectDoer'
                                            options={User}
                                            value={multiUser}
                                            getOptionLabel={(option) => option.name}
                                            getOptionValue={(option) => option.id}
                                            isMulti
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#ddddff',
                                                    primary: '#6161ff',
                                                },
                                            })}
                                            onChange={(data) => {
                                                setMultiUser(data);
                                                const selectedValues = data ? data.map(option => option.id) : [];
                                                const MultiDoers = selectedValues.length > 0 ? JSON.stringify(selectedValues) : "";
                                                setHelpValue({ ...helpValue, cc: MultiDoers })
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <label className="form-label">Title</label>
                                    <input type="text" required className="form-control" value={helpValue.subject} placeholder="Enter title" onChange={(e) => setHelpValue({ ...helpValue, subject: e.target.value })} />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <label className="form-label">Message</label>
                                    <textarea className="form-control" rows="4" value={helpValue.description} placeholder="Enter your message" onChange={(e) => setHelpValue({ ...helpValue, description: e.target.value })}></textarea>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <label className="form-label">File</label>
                                    <input
                                        type="file"
                                        className="form-control"
                                        placeholder="Workflow Icon"
                                        onChange={handleTicketAttachment}
                                        accept=".pdf,.xls,.xlsx,.png,.jpg,.jpeg,.doc,.docx,"
                                    />
                                </div>
                            </div>
                            <div className='col-12'>
                                <div className="form-group">
                                    {preview && (
                                        <div className="uploaded-imgs-wrap d-flex flex-wrap gap-3">

                                            <div className="uploaded-img position-relative">
                                                {/* <button
                                                    id="attachment-remove"
                                                    className="link-btn position-absolute top-0 end-0"
                                                    onClick={() => removeAttachment(index)}
                                                > */}
                                                {/* <i className="fi fi-sr-circle-xmark text-danger"></i>
                                                </button> */}
                                                {/* <i className={` fileIcon`}></i> */}

                                                <div className="gth-attachment-body">
                                                    {preview != null ? preview.split('.').pop() == "png" || preview.split('.').pop() == "jpg" || preview.split('.').pop() == "jpeg" ?
                                                        <figure className="gth-attachment-tile-item">
                                                            <div className="grid-action-btn">
                                                                <OverlayTrigger
                                                                    trigger="click"
                                                                    rootClose
                                                                    placement="auto"
                                                                    overlay={attachmentListAction}
                                                                >
                                                                    <button type="button" className="action-btn">
                                                                        <i className="fas fa-ellipsis-v"></i>
                                                                    </button>
                                                                </OverlayTrigger>
                                                            </div>
                                                            <div className="attachment-image">
                                                                <span className="related-icon"><i className="fas fa-image"></i></span>
                                                            </div>
                                                        </figure> : preview.split('.').pop() == "pdf" ?
                                                            <figure className="gth-attachment-tile-item">
                                                                <div className="grid-action-btn">
                                                                    <OverlayTrigger
                                                                        trigger="click"
                                                                        rootClose
                                                                        placement="auto"
                                                                        overlay={attachmentListAction}
                                                                    >
                                                                        <button type="button" className="action-btn">
                                                                            <i className="fas fa-ellipsis-v"></i>
                                                                        </button>
                                                                    </OverlayTrigger>
                                                                </div>
                                                                <div className="attachment-image">
                                                                    <span className="related-icon"><i className="fas fa-file-pdf"></i></span>
                                                                </div>
                                                            </figure> : preview.split('.').pop() == "xls" || preview.split('.').pop() == "xlsx" ?
                                                                <figure className="gth-attachment-tile-item">
                                                                    <div className="grid-action-btn">
                                                                        <OverlayTrigger
                                                                            trigger="click"
                                                                            rootClose
                                                                            placement="auto"
                                                                            overlay={attachmentListAction}
                                                                        >
                                                                            <button type="button" className="action-btn">
                                                                                <i className="fas fa-ellipsis-v"></i>
                                                                            </button>
                                                                        </OverlayTrigger>
                                                                    </div>
                                                                    <div className="attachment-image">
                                                                        <span className="related-icon"><i className="fas fa-file-excel"></i></span>
                                                                    </div>
                                                                </figure> : preview.split('.').pop() == "doc" ?
                                                                    <figure figure className="gth-attachment-tile-item">
                                                                        <div className="grid-action-btn">
                                                                            <OverlayTrigger
                                                                                trigger="click"
                                                                                rootClose
                                                                                placement="auto"
                                                                                overlay={attachmentListAction}
                                                                            >
                                                                                <button type="button" className="action-btn">
                                                                                    <i className="fas fa-file-word"></i>
                                                                                </button>
                                                                            </OverlayTrigger>
                                                                        </div>
                                                                        <div className="attachment-image">
                                                                            <span className="related-icon"><i className="fas fa-file-excel"></i></span>
                                                                        </div>
                                                                    </figure> : "" : ""
                                                    }
                                                </div>

                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type='submit' className="btn btn-exp-green" >
                            Update
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default EditHelpTicket
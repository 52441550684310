import React, { Suspense, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { UserAuth } from "../pages/auth/Auth";
import Loader from "../loader/Loader";

import TaskProperties from "../pages/setting/TaskProperties";
import MyTaskDone from "../pages/myTask/MyTaskDone";
import Login from "../pages/auth/Login";
import OfficeTiming from "../pages/setting/OfficeTiming";
import Department from "../pages/setting/Department";
import NotificationSetting from "../pages/setting/NotificationSetting";
import WhatsappSetting from "../pages/setting/WhatsappSetting";
import Holiday from "../pages/setting/Holiday";
import CompanyInfo from "../pages/setting/CompanyInfo";
import Users from "../pages/user/Users";
import DeletedUsers from "../pages/user/DeletedUsers";
import RaisedByMe from "../pages/helpTicket/RaisedByMe";
import RaisedToMe from "../pages/helpTicket/RaisedToMe";
import Role from "../pages/setting/role/Role";
import Permission from "../pages/setting/Permission";
import AuditorChecksheet from "../pages/auditor/AuditorChecksheet";
// import AuditorFlow from "../pages/auditor/AuditorFlow";
// import ChartReport from "../pages/chart/ChartReport";
import PermissionRoute from "./PermissionRoute";
import CompanyManagement from "../pages/setting/CompanyManagement";
import ReportsIndex from "../pages/Reports/ReportsIndex";
import PerformanceReport from "../pages/Reports/PerformanceReport";
import TaskReportIndex from "../pages/Reports/TaskReport/TaskReportIndex";
import ChecksheetReportIndex from "../pages/Reports/ChecksheetReport/ChecksheetReportIndex";
import FlowReportIndex from "../pages/Reports/FlowReport/FlowReportIndex";
import VerifyOtp from "../pages/auth/VerifyOtp";
import RePassword from "../pages/auth/RePassword";
import MyFMSDone from "../pages/myTask/MyFMSDone";


const MyCheckSheetDOne = React.lazy(() => import("../pages/myTask/MyCheckSheetDOne"));
const Emailinfo = React.lazy(() => import("../pages/setting/Emailinfo"));
const SystemWorkFlow = React.lazy(() => import("../pages/systemController/SystemWorkFlow"));
const SystemWorkFlowGrid = React.lazy(() => import("../pages/taskManager/workflow/gridview/GridView"));
const AuditorTask = React.lazy(() => import("../pages/auditor/AuditorTask"));
const Master = React.lazy(() => import("../pages/Master"));
const WorkFlow = React.lazy(() => import("../pages/task/WorkFlow"));
const CheckSheet = React.lazy(() => import("../pages/task/CheckSheet"));
const TaskTracker = React.lazy(() => import("../pages/task/TaskTracker"));
const SystemCheckSheet = React.lazy(() => import("../pages/systemController/SystemCheckSheet"));
const Register = React.lazy(() => import('../pages/auth/Register'));
const ForgotPassword = React.lazy(() => import('../pages/auth/ForgotPassword'));
const ProtectedRoute = React.lazy(() => import("./ProtectedRoute"));
const Dashboard = React.lazy(() => import("../pages/Dashboard/Dashboard"));
const EmployeesKpi = React.lazy(() => import("../pages/Reports/managementDashboard/EmployeesKpi"));
const BottleNeckReport = React.lazy(() => import("../pages/Reports/managementDashboard/BottleNeckReport"));
const TaskManagerWorkflow = React.lazy(() => import("../unKnown/TaskManagerWorkflow"));
const NewWorkflowTemplate = React.lazy(() => import("../unKnown/NewWorkflowTemplate"));
const NewWorkflowTask = React.lazy(() => import("../unKnown/NewWorkflowTask"));
const WorkFlowStepDetails = React.lazy(() => import("../unKnown/WorkFlowStepDetails"));
const FlowStepDetails = React.lazy(() => import("../pages/taskManager/workflow/FlowStepDetails"));
const AddNewChecksheet = React.lazy(() => import("../pages/taskManager/checksheet/AddNewChecksheet"));
const SystemTask = React.lazy(() => import('../pages/systemController/SystemTask'));




function PrivateRoutes() {
  const { isLoggedIn, MatchPermission, allPermissions } = UserAuth();

  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route path="/" element={isLoggedIn ? <Navigate to="/dashboard" /> : <Login />} />
        <Route path="/forget-password" element={isLoggedIn ? <Navigate to="/dashboard" /> : <ForgotPassword />} />
        <Route path="/register" element={isLoggedIn ? <Navigate to="/dashboard" /> : <Register />} />
        <Route path="/otp" element={isLoggedIn ? <Navigate to="/dashboard" /> : <VerifyOtp />} />
        <Route path="/update-password" element={isLoggedIn ? <Navigate to="/dashboard" /> : <RePassword />} />

        <Route element={<ProtectedRoute isLogin={isLoggedIn} />}>
          <Route path="/dashboard" element={<Dashboard />} />

          <Route path="/flow" element={<WorkFlow />} />
          <Route path="/employees-kpi" element={<EmployeesKpi />} />
          <Route path="/bottle-neck-report" element={<BottleNeckReport />} />

          <Route path="/my-task-done/:id" element={<MyTaskDone />} />
          <Route path="/:id" element={<MyTaskDone />} />
          <Route path="/my-checksheet-done/:id" element={<MyCheckSheetDOne />} />
          <Route path="/workflow" element={<TaskManagerWorkflow />} />
          <Route path="/new-workflow-template" element={<NewWorkflowTemplate />} />
          <Route path="/create-new-workflow-task" element={<NewWorkflowTask />} />
          <Route path="/flow-step-details" element={<FlowStepDetails />} />
          <Route path="/workflow-step-details" element={<WorkFlowStepDetails />} />

          {/* Task */}
          <Route path="/task" element={<TaskTracker />} />

          {/* checksheet */}
          <Route path="/checksheet" element={<CheckSheet />} />
          {/* chart */}
          {/* <Route path="/chart" element={<ChartReport />} /> */}

          {/* system controller */}
          <Route path="/system-task" element={<SystemTask />} />
          <Route path="/system-checksheet" element={<SystemCheckSheet />} />
          <Route path="/auditor" element={<AuditorTask />} />
          <Route path="/auditor-checksheet" element={<AuditorChecksheet />} />
          {/* <Route path="/auditor-flow" element={<AuditorFlow />} /> */}
          <Route path="/system-flow" element={<SystemWorkFlow />} />
          <Route path="/system-flow-grid" element={<SystemWorkFlowGrid />} />
          {/* Setting URL */}
          <Route path="/office-timing" element={<PermissionRoute allPermission={allPermissions} name={MatchPermission(['Edit Office Time'])}> <OfficeTiming /></PermissionRoute>} />
          <Route path="/department" element={<PermissionRoute allPermission={allPermissions} name={MatchPermission(["Add Department", "View Departments", "Edit Department", "Delete Department"])}><Department /></PermissionRoute>} />
          <Route path="/notification-setting" element={<PermissionRoute allPermission={allPermissions} name={MatchPermission(['General Notification'])}><NotificationSetting /></PermissionRoute>} />
          <Route path="/whatsapp-setting" element={<PermissionRoute allPermission={allPermissions} name={MatchPermission(['WhatsApp Settings'])}><WhatsappSetting /></PermissionRoute>} />
          <Route path="/email-setting" element={<Emailinfo />} />
          <Route path="/task-properties" element={<TaskProperties />} />
          <Route path="/holiday" element={<PermissionRoute allPermission={allPermissions} name={MatchPermission(["Add Holiday", "View Holiday", "Edit Holiday", "Delete Holiday",])}><Holiday /></PermissionRoute>} />
          <Route path="/role" element={<PermissionRoute allPermission={allPermissions} name={MatchPermission(['Role'])}><Role /></PermissionRoute>} />
          <Route path="/permission" element={<Permission />} />
          <Route path="/company-info" element={<PermissionRoute allPermission={allPermissions} name={MatchPermission(['Company Info'])}><CompanyInfo /></PermissionRoute>} />
          <Route path="/company-management" element={<CompanyManagement />} />

          {/* Users Details */}
          <Route path="/users" element={<PermissionRoute allPermission={allPermissions} name={MatchPermission(["View Users", "Edit User"])}><Users /></PermissionRoute>} />
          <Route path="/deleted-users" element={<PermissionRoute allPermission={allPermissions} name={MatchPermission(["Delete User"])}><DeletedUsers /></PermissionRoute>} />
          {/* Help Desk */}
          <Route path="/raised-by-me" element={<RaisedByMe />} />
          <Route path="/raised-to-me" element={<RaisedToMe />} />

          {/* Master */}
          <Route path="/master" element={<PermissionRoute allPermission={allPermissions} name={MatchPermission(["Master Table"])}><Master /></PermissionRoute>} />

          {/* Reports */}
          <Route path="/reports" element={<PermissionRoute allPermission={allPermissions} name={MatchPermission(["Report"])}><ReportsIndex /></PermissionRoute>} />
          <Route path="/performance-report" element={<PerformanceReport />} />
          <Route path="/task-report" element={<TaskReportIndex />} />
          <Route path="/chesksheet-report" element={<ChecksheetReportIndex />} />

          {/* ===========Flow Dynamic report============== */}
          <Route path="/flow-report" element={<FlowReportIndex />} />
          {/* <Route path="/create-flow-report" element={<FlowReportIndex />} /> */}
          {/***************End Flow Dynamic report****************/}
          <Route path="/flow-done/:id" element={<MyFMSDone />} />

        </Route>
      </Routes>
    </Suspense >
  );
}

export default PrivateRoutes;

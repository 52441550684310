import React, { useEffect, useState } from 'react'
import { Offcanvas, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { UserAuth } from '../auth/Auth'
import { PrivateAxios, PrivateAxiosFile, url } from '../../environment/AxiosInstance';
import moment from 'moment';
import { WaringMessage } from '../../environment/ToastMessage';

function HelpTicketMsg({ showChatWrap, handleCloseChatWrap, showChatData, GetCancel, activeTab, GetResolve, GetPending }) {
    const { userDetails, Logout, User } = UserAuth();
    const [fileCount, setFileCount] = useState(0);
    const [msg, setMsg] = useState([]);
    const [msgData, setMsgData] = useState({
        help_ticket_id: "",
        user_id: "",
        message: "",
        file: ""
    })
    useEffect(() => {
        if (showChatData) {
            setMsgData({ ...msgData, help_ticket_id: showChatData.id, user_id: userDetails.id })
            // setMsg(showChatData.msg)
            GetMessage();
        }

    }, [showChatData]);


    const FileUpload = (e) => {
        const file = e.target.files[0];
        setFileCount(e.target.files.length);
        let fileSize = file.size;
        if (Number(fileSize) >= 2097152) {
            WaringMessage("This image in getter than 2MB")
        } else {
            setMsgData({ ...msgData, file: e.target.files[0] });
        }
    }
    const GetMessage = async () => {
        PrivateAxios.get(`ticket/get-message/${showChatData.id}`)
            .then((res) => {
                showChatData.msg = res.data.data
                setMsg(res.data.data)
            }).catch((err) => {
                if (err.response.status == 401) {
                    Logout();
                }
            })
    }


    const SubmitMsg = (e) => {
        e.preventDefault();
        if (msgData.message || msgData.file) {
            const formData = new FormData();
            formData.append("help_ticket_id", msgData.help_ticket_id)
            formData.append("user_id", msgData.user_id)
            formData.append("message", msgData.message)
            formData.append("file", msgData.file)
            PrivateAxiosFile.post("ticket/post-message", formData)
                .then((res) => {
                    GetMessage();
                    setMsgData({ ...msgData, message: "", file: "" })
                    if (activeTab == "pending") {
                        GetPending();
                    }
                    if (activeTab == "resolve") {
                        GetResolve();
                    }
                    if (activeTab == "close") {
                        GetCancel();
                    }
                    setFileCount(0)
                }).catch((err) => {
                    if (err.response.status == 401) {
                        Logout();
                    }
                })
        } else {
            WaringMessage("Please give some message")
        }

    }

    return (
        <Offcanvas id="ticket-chat" className="chat-canvas" show={showChatWrap} onHide={handleCloseChatWrap} placement="end" backdrop="static">
            <Offcanvas.Header closeButton className="d-block gth-blue-light-bg">
                <Offcanvas.Title className="gth-modal-title">Ticket ID No. <span>{showChatData && showChatData.id}</span></Offcanvas.Title>
                <div className="short-details-wrap mt-2">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="mb-2">
                                <label className="form-label">Assignee</label>
                                {showChatData && showChatData.assigned_by && User && User.map((item) => (
                                    item.id == showChatData.assigned_by ?
                                        < div className="profile-wrap" key={item.id}>
                                            <div className="exp-avtar bg-exp-yellow">
                                                {item.user_photo != null ?
                                                    < img className="prof-img" src={url + item.user_photo} alt="user" /> :
                                                    <span>{item.name != '' ? item.name.split(' ').length > 1 ? item.name.split(' ')[0].split('')[0] + item.name.split(' ')[1].split('')[0] : item.name.split('')[0] + item.name.split('')[1] : ""}</span>
                                                }
                                            </div>
                                            <div className="ps-2 profile-name-wrap text-truncate">
                                                <h5 className="profile-name text-nowrap text-truncate">{item.name}</h5>
                                            </div>
                                        </div> : ""
                                ))
                                }
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-2">
                                <label className="form-label">Doer</label>
                                {showChatData && showChatData.assign_to && User && User.map((item) => (
                                    item.id == showChatData.assign_to ?
                                        < div className="profile-wrap" key={item.id}>
                                            <div className="exp-avtar bg-exp-yellow">
                                                {item.user_photo != null ?
                                                    < img className="prof-img" src={url + item.user_photo} alt="user" /> :
                                                    <span>{item.name != '' ? item.name.split(' ').length > 1 ? item.name.split(' ')[0].split('')[0] + item.name.split(' ')[1].split('')[0] : item.name.split('')[0] + item.name.split('')[1] : ""}</span>
                                                }
                                            </div>
                                            <div className="ps-2 profile-name-wrap text-truncate">
                                                <h5 className="profile-name text-nowrap text-truncate">{item.name}</h5>
                                            </div>
                                        </div> : ""
                                ))
                                }
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-2">
                                <label className="form-label">Observer</label>
                                {showChatData && showChatData.users && showChatData.users.length > 0 ?
                                    <div className="d-flex flex-wrap multiple-ovserver">
                                        {
                                            showChatData.users.map((itemObserve) => (
                                                User && User.map((item) => (
                                                    item.id == itemObserve.user_id ?
                                                        itemObserve.user_id != showChatData.assign_to ?

                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={
                                                                    <Tooltip >
                                                                        {item.name}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                < div className="profile-wrap" key={item.id}>
                                                                    <div className="exp-avtar bg-exp-green">
                                                                        {item.user_photo != null ?
                                                                            < img className="prof-img" src={url + item.user_photo} alt="user" /> :
                                                                            <span>{item.name != '' ? item.name.split(' ').length > 1 ? item.name.split(' ')[0].split('')[0] + item.name.split(' ')[1].split('')[0] : item.name.split('')[0] + item.name.split('')[1] : ""}</span>
                                                                        }
                                                                    </div>

                                                                </div>

                                                            </OverlayTrigger>
                                                            : "" : ""))))}</div> : ""
                                }

                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-2">
                                <label className="form-label">Date</label>
                                <p className="mb-0 f-s-14">{showChatData && moment(showChatData.created_at).format('DD-MM-YYYY ')}</p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-2">
                                <label className="form-label">Status</label>
                                {showChatData && showChatData != '' ? showChatData.status == 0 ?
                                    <div className="status-item todo">
                                        <i className="far fa-dot-circle me-2"></i>
                                        <span>Pending</span>
                                    </div> :
                                    showChatData.status == 1 ?
                                        <div className="status-item todo">
                                            <i className="fas fa-check-circle me-2"></i>
                                            <span>Resolve</span>
                                        </div> : showChatData.status == 2 ?
                                            <div className="status-item todo">
                                                <i className="far fa-times-circle me-2"></i>
                                                <span>Close</span>
                                            </div> : "" : ""
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <div className="chat-wrapper">
                    <div className="chat-conversation-wrap">
                        {showChatData && msg.length > 0 ? msg.map((item) => (
                            item.user_id == userDetails.id ?
                                <div className="chat-msg">
                                    <div className="chat-msg-profile-image">
                                        {showChatData && User && User.map((UserItem) => (
                                            UserItem.id == userDetails.id ?
                                                < div className="profile-wrap" key={UserItem.id}>
                                                    <div className="exp-avtar bg-exp-green">
                                                        {UserItem.user_photo != null ?
                                                            < img className="prof-img" src={url + UserItem.user_photo} alt="user" /> :
                                                            <span>{UserItem.name != '' ? UserItem.name.split(' ').length > 1 ? UserItem.name.split(' ')[0].split('')[0] + UserItem.name.split(' ')[1].split('')[0] : UserItem.name.split('')[0] + UserItem.name.split('')[1] : ""}</span>
                                                        }
                                                    </div>
                                                </div> : ""
                                        ))
                                        }
                                    </div>
                                    <div className="chat-msg-content">
                                        <div className="chat-profile-name text-truncate">
                                            {User.find(data => data.id == userDetails.id).name}
                                        </div>
                                        <div className="chat-msg-text">
                                            {item.message && item.message != null ?
                                                item.message && item.message : ""
                                            }
                                            {
                                                item.file && item.file != null ?
                                                    <div className="chat-attachment-item">
                                                        {item.file && item.file.split('.').pop() == "png" || item.file.split('.').pop() == "jpg" || item.file.split('.').pop() == "jpeg" ?
                                                            <div className="icon">
                                                                <i className="fas fa-image"></i>
                                                            </div> : item.file.split('.').pop() == "pdf" ?
                                                                <div className="icon">
                                                                    <i className="fas fa-file-pdf"></i>
                                                                </div> : item.file.split('.').pop() == "doc" ?
                                                                    <div className="icon">
                                                                        <i className="fas fa-file-word"></i>
                                                                    </div> : item.file.split('.').pop() == "xls" || item.file.split('.').pop() == "xlsx" ?
                                                                        <div className="icon">
                                                                            <i className="fas fa-file-excel"></i>
                                                                        </div> : ""
                                                        }
                                                        <p className="text-truncate filename">{item.file && item.file}</p>
                                                        <button className="attachment-download action-btn" onClick={() => {
                                                            const link = document.createElement('a');
                                                            const fileExtension = item.file.split('.').pop();
                                                            link.href = url + item.file;
                                                            link.download = `ticket.${fileExtension}`;
                                                            link.target = "_blank";
                                                            document.body.appendChild(link);
                                                            link.click();
                                                            document.body.removeChild(link);
                                                        }}>
                                                            <i className="fas fa-download"></i>
                                                        </button>
                                                    </div> : ""
                                            }
                                        </div>
                                        <div className="chat-msg-date">
                                            {item.created_at && moment(item.created_at).format('DD-MM-YYYY | hh:mm A')}
                                        </div>
                                    </div>
                                </div> :
                                <div className="chat-msg owner">
                                    <div className="chat-msg-profile-image">
                                        {showChatData && User && User.map((userItem) => (
                                            userItem.id == item.user_id ?
                                                <div className="profile-wrap" key={userItem.id}>
                                                    <div className="exp-avtar bg-exp-green">
                                                        {userItem.user_photo != null ?
                                                            < img className="prof-img" src={url + userItem.user_photo} alt="user" /> :
                                                            <span>{userItem.name != '' ? userItem.name.split(' ').length > 1 ? userItem.name.split(' ')[0].split('')[0] + userItem.name.split(' ')[1].split('')[0] : userItem.name.split('')[0] + userItem.name.split('')[1] : ""}</span>
                                                        }
                                                    </div>
                                                    {/* <div className="ps-2 profile-name-wrap text-truncate">
                                                        <h5 className="profile-name text-nowrap text-truncate">{userItem.name}</h5>
                                                    </div> */}
                                                </div> : ""
                                        ))
                                        }
                                    </div>
                                    <div className="chat-msg-content">
                                        <div className="chat-profile-name text-truncate">
                                            {User.find(data => data.id == item.user_id).name}
                                        </div>
                                        <div className="chat-msg-text">
                                            {item.message && item.message != null ?
                                                item.message && item.message : ""
                                            }
                                            {
                                                item.file && item.file != null ?
                                                    <div className="chat-attachment-item">
                                                        {item.file && item.file.split('.').pop() == "png" || item.file.split('.').pop() == "jpg" || item.file.split('.').pop() == "jpeg" ?
                                                            <div className="icon">
                                                                <i className="fas fa-image"></i>
                                                            </div> : item.file.split('.').pop() == "pdf" ?
                                                                <div className="icon">
                                                                    <i className="fas fa-file-pdf"></i>
                                                                </div> : item.file.split('.').pop() == "doc" ?
                                                                    <div className="icon">
                                                                        <i className="fas fa-file-word"></i>
                                                                    </div> : item.file.split('.').pop() == "xls" || item.file.split('.').pop() == "xlsx" ?
                                                                        <div className="icon">
                                                                            <i className="fas fa-file-excel"></i>
                                                                        </div> : ""
                                                        }
                                                        <p className="text-truncate filename">{item.file && item.file}</p>
                                                        <button className="attachment-download action-btn" onClick={() => {
                                                            const link = document.createElement('a');
                                                            link.href = url + item.file;
                                                            const fileExtension = item.file.split('.').pop();
                                                            link.download = `ticket.${fileExtension}`;
                                                            link.target = "_blank";
                                                            document.body.appendChild(link);
                                                            link.click();
                                                            document.body.removeChild(link);
                                                        }}>
                                                            <i className="fas fa-download"></i>
                                                        </button>
                                                    </div> : ""
                                            }
                                        </div>
                                        <div className="chat-msg-date">
                                            {item.created_at && moment(item.created_at).format('DD-MM-YYYY | hh:mm A')}
                                        </div>
                                    </div>
                                </div>
                        )) : ""
                        }
                    </div>
                    <form onSubmit={SubmitMsg} className="chat-area-footer">
                        <div className="chat-file-upload custom-file-upload">
                            <i className="fas fa-paperclip"></i>
                            <input
                                type="file"
                                accept=".png, .jpg, .jpeg, .pdf, .xls, .xlsx, .doc"
                                name="myfile"
                                className="input-upload"
                                onChange={FileUpload}
                            // multiple  // Allow multiple files if needed
                            />
                            {fileCount > 0 && (
                                <span className="file-badge">
                                    {fileCount}
                                </span>
                            )}
                        </div>
                        <input
                            type="text"
                            placeholder="Type something here..."
                            value={msgData.message}
                            className="chat-input"
                            onChange={(e) => setMsgData({ ...msgData, message: e.target.value })}
                        />
                        <button type="submit" className="send-btn">
                            <i className="fas fa-paper-plane"></i>
                        </button>
                    </form>
                </div>

            </Offcanvas.Body>
        </Offcanvas>
    )
}

export default HelpTicketMsg
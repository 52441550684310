import React, { useEffect, useState } from 'react'
import { Collapse, Modal } from 'react-bootstrap'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import Select from 'react-select'
import { PrivateAxios } from '../../environment/AxiosInstance'
import { ErrorMessage, SuccessMessage, WaringMessage } from '../../environment/ToastMessage'
import { UserAuth } from '../auth/Auth'

function EditUser({ editUserShow, editUserModalClose, department, role, GetUser, editUserValue, permissionInitial }) {
    const [selectRole, setSelectRole] = useState([]);
    const [groups, setGroups] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [allPermission, setAllPermission] = useState([]);
    const [ExtraPermission, setExtraPermission] = useState([]);
    const { GetUserPermission, Logout, auditUserList } = UserAuth();
    const [userData, setUserData] = useState({
        "name": "",
        "email": "",
        "p_isd": "",
        "phone": "",
        "w_isd": "",
        "wsnumber": "",
        "depertment_id": "",
    });

    const handleSelectAllChange = (e) => {
        const newValue = e.target.checked;
        setSelectAll(newValue);
        setGroups(prevent => prevent.map(group => ({ ...group, selected: newValue, allmodule: group.allmodule.map(item => ({ ...item, selected: newValue })) })))
        if (newValue) {
            const permissionsToAdd = [];
            groups.forEach(group => {
                group.allmodule.forEach(item => {
                    permissionsToAdd.push({ module_id: group.id, permission_id: item.id, name: item.name });
                });
            });
            setAllPermission([...allPermission, ...permissionsToAdd]);
            setExtraPermission([...ExtraPermission, ...permissionsToAdd])
        } else {
            setAllPermission([]);
            setExtraPermission([])
        }
    };
    const handleGroupChange = (groupId, e) => {
        const newValue = e.target.checked;
        setGroups(groups.map(group => group.id === groupId ? { ...group, selected: newValue, allmodule: group.allmodule.map(item => ({ ...item, selected: newValue })) } : group));
        if (newValue) {
            const permissionsToAdd = groups
                .find(group => group.id === groupId)
                .allmodule.map(item => ({ module_id: groupId, permission_id: item.id, name: item.name }));
            setAllPermission([...allPermission, ...permissionsToAdd]);
            setExtraPermission([...ExtraPermission, ...permissionsToAdd])
        } else {
            setAllPermission(allPermission.filter(
                permission => permission.module_id !== groupId
            ));
            setExtraPermission(ExtraPermission.filter(
                permission => permission.module_id !== groupId
            ))
        }
    };

    const handleItemChange = (groupId, itemId, name, e) => {
        if (e.target.checked) {
            setAllPermission([...allPermission, { module_id: groupId, permission_id: itemId, name: name }])
            setExtraPermission([...ExtraPermission, { module_id: groupId, permission_id: itemId, name: name }])
        } else {
            setAllPermission(allPermission.filter(item =>
                !(item.module_id === groupId && item.permission_id === itemId)
            ));
            setExtraPermission(ExtraPermission.filter(item =>
                !(item.module_id === groupId && item.permission_id === itemId)
            ));
        }
        setGroups(groups.map(group =>
            group.id === groupId ? {
                ...group,
                allmodule: group.allmodule.map(item =>
                    item.id === itemId ? { ...item, selected: !item.selected } : item
                ),
                selected: group.allmodule.every(item => item.id === itemId ? !item.selected : item.selected)
            } : group
        ));

        const allGroupsSelected = groups.every(group =>
            group.allmodule.every(item => item.selected)
        );
        setSelectAll(allGroupsSelected);
    };

    // Clear all state when modal is closed
    const clearALL = () => {
        setAllPermission([]);
        setSelectRole([]);
        setGroups([]);
        setUserData({
            "name": "",
            "email": "",
            "p_isd": "",
            "phone": "",
            "w_isd": "",
            "wsnumber": "",
            "depertment_id": "",
        });
        setSelectAll(false);
    }

    // Initialize groups when modal opens
    useEffect(() => {
        if (editUserShow) {
            if (permissionInitial.length > 0) {
                // Deep copy to prevent mutation
                const initialGroups = JSON.parse(JSON.stringify(permissionInitial));
                setGroups(initialGroups);
            }
        }
    }, [permissionInitial, editUserShow]);

    // Initialize user data and permissions when modal opens
    useEffect(() => {
        if (editUserShow) {
            if (editUserValue) {
                setUserData({
                    name: editUserValue.name,
                    email: editUserValue.email,
                    p_isd: editUserValue.p_isd,
                    phone: editUserValue.phone_number,
                    w_isd: editUserValue.w_isd,
                    wsnumber: editUserValue.whatsapp_no,
                    depertment_id: editUserValue.departments_id,
                });

                // Set selected roles
                // const existRole = editUserValue.role[0];
                console.log(editUserValue.role);

                if (editUserValue.role.length > 0) {
                    let roleIds = []
                    editUserValue.role.map(roleId =>
                        roleIds.push({ role_id: roleId.role_id })
                    );
                    setSelectRole(roleIds)
                    let permissionData = [];
                    let extraPermissions = [];
                    editUserValue.role.map(roleId => {
                        if (roleId.permission_id) {
                            const permissionID = JSON.parse(roleId.permission_id)
                            permissionID.map((item) => {
                                if (!permissionData.includes(item)) {
                                    permissionData.push(item)
                                }
                            })
                        }
                        if (roleId.role_id == 0) {
                            if (roleId.permission_id) {
                                const permissionIDS = JSON.parse(roleId.permission_id)
                                permissionIDS.map((item) => {
                                    extraPermissions.push(item)
                                })
                            }
                        }
                    }
                    );
                    if (permissionData.length > 0) {
                        setGroups(prevGroups => prevGroups.map(group => {
                            const updatedModules = group.allmodule.map(module => {
                                const selected = permissionData.includes(module.id);
                                return { ...module, selected };
                            });
                            const selectedGroup = updatedModules.every(module => module.selected);
                            return { ...group, allmodule: updatedModules, selected: selectedGroup };
                        }));
                        const allPermissions = [];
                        const allExtraPermissions = [];
                        permissionInitial.forEach(group => {
                            group.allmodule.forEach(module => {
                                if (permissionData.includes(module.id)) {
                                    allPermissions.push({ module_id: group.id, permission_id: module.id, name: module.name });
                                }
                                if (extraPermissions.includes(module.id)) {
                                    allExtraPermissions.push({ module_id: group.id, permission_id: module.id, name: module.name });
                                }
                            });
                        });
                        setAllPermission(allPermissions);
                        setExtraPermission(allExtraPermissions)
                    }
                }
            } else {
                clearALL();
            }
        }
    }, [editUserShow, editUserValue, permissionInitial]);
    useEffect(() => {
        const allSelected = groups.every(group => group.selected);
        setSelectAll(allSelected);
    }, [groups]);


    const submitUser = (e) => {
        e.preventDefault();
        if (userData.p_isd == "" || userData.phone == "" || userData.w_isd == "" || userData.wsnumber == "") {
            WaringMessage("Please give your Mobile number and whatsApp number")
        } else {
            const payload = {
                ...userData,
                roles: selectRole.length > 0 ? JSON.stringify(selectRole) : "",
                permission: allPermission.length > 0 ? JSON.stringify(allPermission) : "",
                extraPermission: ExtraPermission.length > 0 ? JSON.stringify(ExtraPermission) : "",
            }
            PrivateAxios.put(`user/update-user/${editUserValue.id}`, payload)
                .then((res) => {
                    GetUser();
                    GetUserPermission();
                    auditUserList();
                    SuccessMessage(res.data.msg)
                    editUserModalClose();
                }).catch((err) => {
                    ErrorMessage(err.response.data.msg)
                })
        }

    }

    //role permission collapse
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);



    return (
        <Modal id="editUserModal" show={editUserShow} onHide={() => { editUserModalClose(); clearALL() }} backdrop="static" keyboard={false} centered size="lg">
            <Modal.Header closeButton className="gth-blue-light-bg">
                <Modal.Title className="gth-modal-title">Update User</Modal.Title>
            </Modal.Header>
            <form onSubmit={submitUser}>
                <Modal.Body className='pb-1'>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className="form-group">
                                <label className="form-label">Name <span className="text-exp-red">*</span></label>
                                <input type="text" value={userData.name} required className="form-control" onChange={(e) => setUserData({ ...userData, name: e.target.value })} />
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className="form-group">
                                <label className="form-label">Email <span className="text-exp-red">*</span></label>
                                <input type="email" value={userData.email} required className="form-control" onChange={(e) => setUserData({ ...userData, email: e.target.value })} />
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className="form-group">
                                <label className="form-label">Phone <span className="text-exp-red">*</span></label>
                                <PhoneInput
                                    country={'in'}
                                    value={`${userData.p_isd}${userData.phone}`}
                                    onChange={(value, country) => {
                                        const code = `${country.dialCode}`;
                                        const number = value.replace(code, '');
                                        setUserData({ ...userData, phone: number, p_isd: code, wsnumber: number, w_isd: code })
                                    }}
                                />
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className="form-group">
                                <label className="form-label">WhatsApp</label>
                                <PhoneInput
                                    required
                                    country={'in'}
                                    value={`${userData.w_isd}${userData.wsnumber}`}
                                    onChange={(value, country) => {
                                        const code = `${country.dialCode}`;
                                        const number = value.replace(code, '');
                                        setUserData({ ...userData, wsnumber: number, w_isd: code })
                                    }}
                                />
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className="form-group">
                                <label className="form-label">Department <span className="text-exp-red">*</span></label>
                                <div className='custom-select-wrap'>
                                    <Select
                                        required
                                        name='department'
                                        options={department}
                                        value={department.find(item => item.id == userData.depertment_id) || ''}
                                        getOptionLabel={(option) => option.title}
                                        getOptionValue={(option) => option.id}
                                        theme={(theme) => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                primary25: '#ddddff',
                                                primary: '#6161ff',
                                            },
                                        })}
                                        onChange={(data) => setUserData({ ...userData, depertment_id: data.id })}
                                    />
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <div className="card shadow-none border">
                                    <div className="card-header bg-primary-grey-light-2 d-flex align-items-center justify-content-between">
                                        <h6 className="mb-0"><i className="fas fa-user-shield me-2 gth-text-primary"></i>Role Management</h6>
                                        <label
                                            onClick={() => setOpen(!open)}
                                            aria-controls="example-collapse-text"
                                            aria-expanded={open}
                                            className='icon-btn mb-0 ms-auto cursor-pointer'
                                        >
                                            <i className="fas fa-sort"></i>
                                        </label>
                                    </div>
                                    <Collapse in={open}>
                                        <div className="card-body pb-3">
                                            <div className="d-flex flex-wrap">
                                                {role.length > 0 ? role.map((item) => (
                                                    <label className="custom-checkbox me-3 mb-2">
                                                        <input type="checkbox" checked={selectRole.find((data) => data.role_id === item.id) ? true : false}
                                                            onChange={(e) => {
                                                                if (e.target.checked) {
                                                                    if (item.id === 10 || item.id === 11 || item.id === 12) {
                                                                        setSelectRole((prevSelected) =>
                                                                            prevSelected.filter(
                                                                                (role) => !(role.role_id === 10 || role.role_id === 11 || role.role_id === 12)
                                                                            )
                                                                        );
                                                                    }
                                                                    setSelectRole((prevSelected) => [...prevSelected, { role_id: item.id }]);
                                                                } else {
                                                                    setSelectRole((prevSelected) =>
                                                                        prevSelected.filter((role) => role.role_id !== item.id)
                                                                    );
                                                                }
                                                            }} />
                                                        <span className="checkmark" />
                                                        <span className="text-">{item.name}</span>
                                                    </label>)) : ""
                                                }
                                            </div>
                                        </div>
                                    </Collapse>
                                </div>
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className='form-group'>
                                <div className='card shadow-none border'>
                                    <div className="card-header bg-primary-grey-light-2 d-flex align-items-center justify-content-between">
                                        <h6 className="mb-0"><i className="fas fa-cogs me-2 gth-text-primary"></i>Additional Permissions</h6>
                                        <label
                                            onClick={() => setOpen2(!open2)}
                                            aria-controls="example-collapse-text"
                                            aria-expanded={open}
                                            className='icon-btn mb-0 ms-auto cursor-pointer'
                                        >
                                            <i className="fas fa-sort"></i>
                                        </label>
                                    </div>
                                    <Collapse in={open2}>
                                        <div className='card-body role-permission-card'>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group'>
                                                        <label className="custom-checkbox mb-0">
                                                            <input type="checkbox" checked={selectAll} onChange={handleSelectAllChange} />
                                                            <span className="checkmark" />
                                                            <span className="text- text-dark">All</span>
                                                        </label>
                                                    </div>
                                                </div>
                                                {
                                                    groups && groups.map((item, i) => (
                                                        <div className='col-lg-4 col-md-6 col-sm-12'>
                                                            <div className='form-group'>
                                                                <label className={`custom-checkbox mb-2`}>
                                                                    <input type="checkbox" checked={item.selected} onChange={(e) => handleGroupChange(item.id, e)} />
                                                                    <span className="checkmark" />
                                                                    <span className="text- text-dark">{item.name}</span>
                                                                </label>
                                                                <div className='ps-3'>
                                                                    {
                                                                        item.allmodule && item.allmodule.map((data) => (
                                                                            <label className={`custom-checkbox mb-2`}>
                                                                                <input type="checkbox" checked={data.selected} onChange={(e) => {
                                                                                    handleItemChange(item.id, data.id, data.name, e)

                                                                                }} />
                                                                                <span className="checkmark" />
                                                                                <span className="text-">{data.name}</span>
                                                                            </label>
                                                                        ))
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                }

                                            </div>
                                        </div>
                                    </Collapse>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className='btn btn-exp-green'>
                        Update
                    </button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}

export default EditUser
import React, { useEffect, useState } from 'react'
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { PrivateAxios } from '../../environment/AxiosInstance'
import moment from 'moment'
import SubmitWorkFlow from '../taskManager/MyWorkFlow/SubmitWorkFlow'
import { WaringMessage } from '../../environment/ToastMessage'
import { jwtDecode } from 'jwt-decode'
import { useParams } from 'react-router-dom'

function MyFMSDone() {
    const [task, setTask] = useState('')
    const { id } = useParams()
    const params = jwtDecode(id);

    const GetFMs = () => {
        PrivateAxios.get(`fms/get-single-fms/${params.id}`)
            .then((res) => {
                setTask(res.data.data)
            }).catch((err) => {

            })
    }
    useEffect(() => {
        GetFMs();
    }, []);
    //=====================Submit Task Model====================//
    //**********************************************************/
    const [flowSubmitModalShow, setFlowSubmitModalShow] = useState(false);
    const [submitForm, setSubmitForm] = useState('')

    const flowSubmitModalClose = () => {
        setFlowSubmitModalShow(false);
        setSubmitForm('')
    }
    // const openFlowSubmitModalShow = (data) => {

    // }

    const SubmitRemark = () => {
        if (task.status == 4 || task.status == 3) {
            WaringMessage("This Task Already Done Or Skip !")
            return;
        } else {
            if (task.show == 0) {
                WaringMessage("Previous Work Flow does not complete !")
                return;
            }
            setSubmitForm(task);
            setFlowSubmitModalShow(true);
        }
    }

    return (
        <>
            <div className='col-12 px-4 py-4'>
                <div className='card'>
                    <div className='card-header'>
                        <div className='d-flex justify-content-between'>
                            <h5 className='card-title font-weight-medium'>
                                {task && task.fms.name + `(${task.step.name})`}
                            </h5>
                            {/* <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip>
                                        Chat
                                    </Tooltip>
                                }
                            > */}
                            {/* <button className="me-1 table-action-btn" onClick={() => handleMessageShow(task)}  >
                                    <i className="fas fa-comment-dots"></i>
                                </button> */}
                            {/* </OverlayTrigger> */}
                        </div>
                    </div>
                    <Modal.Body className='pb-1'>
                        <div className='row'>
                            {/* <div className='col-md-6'>
                            <div className="form-group d-flex">
                                <label className="form-label">Assigned By : </label>
                                <p className='mb-0'>{task && task.assignedByUser.name}</p>
                            </div>
                        </div> */}
                            <div className='col-md-6'>
                                <div className="form-group d-flex">
                                    <label className="form-label">Planned Date : </label>
                                    <p className='mb-0'>{task && moment(task.planned_date).format("DD-MM-YYYY hh:mm A")}</p>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type='submit' onClick={SubmitRemark} className='btn btn-exp-primary'>
                            Done
                        </button>
                    </Modal.Footer>
                </div>
            </div >
            <SubmitWorkFlow flowSubmitModalShow={flowSubmitModalShow} submitForm={submitForm} flowSubmitModalClose={flowSubmitModalClose} inProgressTask={''} />
        </>
    )
}

export default MyFMSDone
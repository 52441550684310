import React, { useEffect, useState } from 'react'
import Loader from '../../loader/Loader';
import PhoneInput from 'react-phone-input-2';
import { PrivateAxios, PrivateAxiosFile, url } from '../../environment/AxiosInstance';

import { UserAuth } from '../auth/Auth';
import { SuccessMessage, WaringMessage } from '../../environment/ToastMessage';

function CompanyInfo() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState('');
  const { Logout, companyInfoGlobal, GetCompany } = UserAuth();
  const [image, setImage] = useState('');
  // const GetCompany = () => {
  //   setLoading(true)
  //   PrivateAxios.get('company/get-company-detail')
  //     .then((res) => {
  //       setLoading(false)
  //       setData(res.data.data)
  //     }).catch((err) => {
  //       setLoading(false)
  //     })
  // }

  const [conpanyInfo, setCompanyInfo] = useState({
    "company_name": "",
    "company_email": "",
    "p_isd": "",
    "company_phone": "",
    "company_address": "",
    "company_logo": ""
  })

  useEffect(() => {
    if (companyInfoGlobal) {
      setData(companyInfoGlobal)
      setCompanyInfo({
        ...conpanyInfo,
        company_name: companyInfoGlobal.company_name,
        company_email: companyInfoGlobal.company_email,
        p_isd: companyInfoGlobal.p_isd,
        company_phone: companyInfoGlobal.company_phone,
        company_address: companyInfoGlobal.company_address,
        company_logo: "",
        gst_no: companyInfoGlobal.gst_no
      })
      setImage(url + companyInfoGlobal.company_logo)
    }
  }, [companyInfoGlobal])

  const handlePhoneChange = (value, country) => {
    const code = `${country.dialCode}`;
    const number = value.replace(code, '');
    setCompanyInfo({ ...conpanyInfo, company_phone: number, p_isd: code })
  };
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file?.name.split('.').pop() == "png" || file?.name.split('.').pop() == "jpeg" || file?.name.split('.').pop() == "jpg") {
      if (file) {
        setCompanyInfo({ ...conpanyInfo, company_logo: file })
        const reader = new FileReader();
        reader.onloadend = () => {
          setImage(reader.result);
        };
        reader.readAsDataURL(file);
      }
    } else {
      WaringMessage("Please change file extension !")
    }


  };

  const updateCompany = (e) => {
    e.preventDefault();
    let formData = new FormData()
    formData.append("company_address", conpanyInfo.company_address)
    formData.append("company_email", conpanyInfo.company_email)
    formData.append("company_name", conpanyInfo.company_name)
    formData.append("company_phone", conpanyInfo.company_phone)
    formData.append("file", conpanyInfo.company_logo)
    formData.append("p_isd", conpanyInfo.p_isd)
    formData.append("image", data.company_logo)
    formData.append("gst_no", conpanyInfo.gst_no)
    PrivateAxiosFile.post("company-info", formData)
      .then((res) => {
        SuccessMessage(res.data.msg)
        GetCompany();
      }).catch((err) => {
        console.log(err);

        if (err.response.status == 401) {
          Logout();
        }
      })

  }


  return (
    <React.Fragment>
      {loading ? <Loader /> :
        <div className='p-4'>
          <form className='card' onSubmit={updateCompany}>
            <div className='card-body pb-1'>
              <div className='row'>
                <div className='col-lg-3 col-md-6 col-sm-12 col-12'>
                  <div className="form-group">
                    <label className="form-label">Company Name <span className="text-exp-red">*</span></label>
                    <input type="text" value={conpanyInfo.company_name} className="form-control" placeholder="Company Name" onChange={(e) => setCompanyInfo({ ...conpanyInfo, company_name: e.target.value })} />
                  </div>
                </div>
                <div className='col-lg-3 col-md-6 col-sm-12 col-12'>
                  <div className="form-group">
                    <label className="form-label">Company Email <span className="text-exp-red">*</span></label>
                    <input type="email" value={conpanyInfo.company_email} className="form-control" placeholder="Company Name" onChange={(e) => setCompanyInfo({ ...conpanyInfo, company_email: e.target.value })} />
                  </div>
                </div>
                <div className='col-lg-3 col-md-6 col-sm-12 col-12'>
                  <div className="form-group">
                    <label className="form-label">Phone <span className="text-exp-red">*</span></label>
                    <PhoneInput
                      country={'in'}
                      value={data && `${conpanyInfo.p_isd}${conpanyInfo.company_phone}`}
                      onChange={(value, country) => handlePhoneChange(value, country)}
                    />
                  </div>
                </div>
                <div className='col-lg-3 col-md-6 col-sm-12 col-12'>
                  <div className="form-group">
                    <label className="form-label">GST NO. <span className="text-exp-red">*</span></label>
                    <input required type="text" value={conpanyInfo.gst_no} className="form-control" placeholder="GST No" onChange={(e) => setCompanyInfo({ ...conpanyInfo, gst_no: e.target.value })} />
                  </div>
                </div>
                <div className='col-lg-8 col-md-12 col-sm-12 col-12'>
                  <div className="form-group">
                    <label className="form-label">Address</label>
                    <textarea rows="8" value={conpanyInfo.company_address} className="form-control" placeholder="Address" onChange={(e) => setCompanyInfo({ ...conpanyInfo, company_address: e.target.value })}></textarea>
                  </div>
                </div>
                {image != '' ?
                  <div className='col-lg-4 col-md-6 col-sm-12 col-12'>
                    <div className="form-group">
                      <label className="form-label">Company Logo </label>
                      <input type="file" className="form-control" accept='image/jpeg, image/png,image/jpg' placeholder="Company Logo" onChange={handleImageChange} />
                      <div className='view-company-logo mt-2 p-2 border rounded text-center'>
                        <img src={image} alt="Logo" className="img-fluid" />
                      </div>
                    </div>
                  </div> : ""
                }
              </div>
            </div>
            <div className='card-footer d-flex justify-content-end'>
              <button type='submit' className='btn btn-exp-green'>Update</button>
            </div>
          </form>
        </div>
      }
    </React.Fragment>

  )
}

export default CompanyInfo
import React, { useState } from 'react';
import { Offcanvas } from 'react-bootstrap';
import { UserAuth } from '../pages/auth/Auth';
import { url } from '../environment/AxiosInstance';

function UserOffcanvas({ show, handleClose, title, onSelectUser }) {
    const { User } = UserAuth();
    const [users, setUsers] = useState(User)
    const [inputName, setInputName] = useState('')

    const handleSearch = (e) => {
        setInputName(e.target.value)
        const filteredItems = User.filter((item) => {
            return item && item.name && item.name.toLowerCase().replace(/\s+/g, '').includes(e.target.value.toLowerCase().replace(/\s+/g, ''))
        }
        );
        setUsers(filteredItems);
    }

    const handleUserClick = (user) => {
        setUsers(User);
        setInputName('')
        onSelectUser(user);
    };
    const clear = () => {
        setUsers(User);
        setInputName('')
    }

    return (
        <Offcanvas className="assignee-canvas" show={show} onHide={() => { handleClose();clear() }} placement="end">
            <Offcanvas.Header closeButton className="d-block">
                <Offcanvas.Title>{title}</Offcanvas.Title>
                <div className="search-assignee">
                    <span className="search-assignee-icon">
                        <i className="fas fa-search"></i>
                    </span>
                    <input type="text" className="form-control" value={inputName} placeholder="Search or enter email.." onChange={handleSearch} />
                </div>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <div id="selectAssignee" className="assignee-wrapper assignee-wrapper-body">
                    <div className="user-list-wrap">
                        {users.map(user => (
                            <div className="user-item" key={user.id} onClick={() => handleUserClick(user)}>
                                <div className="profile-wrap">
                                    {/* <div className="exp-avtar">
                                        <img className="prof-img" src={process.env.PUBLIC_URL + 'assets/images/users/1694671741_1517351722263.jpg'} alt="user" />
                                    </div> */}
                                    <div className="exp-avtar bg-exp-green">
                                        {user.user_photo != null ?
                                            < img className="prof-img" src={url + user.user_photo} alt="user" /> :
                                            <span>{user.name != '' ? user.name.split(' ').length > 1 ? user.name.split(' ')[0].split('')[0] + user.name.split(' ')[1].split('')[0] : user.name.split('')[0] + user.name.split('')[1] : ""}</span>
                                        }
                                    </div>
                                    <div className="ps-2 profile-name-wrap text-truncate">
                                        <h5 className="profile-name text-nowrap text-truncate">{user.name}</h5>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    );
}

export default UserOffcanvas;


import React, { useEffect, useRef, useState, useCallback } from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DataTable from 'react-data-table-component';
import { Modal, Offcanvas, OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';
import { HotTable } from '@handsontable/react';
import { registerAllModules } from 'handsontable/registry';
import 'handsontable/dist/handsontable.full.min.css';
import { PrivateAxios, PrivateAxiosFile, url } from '../../environment/AxiosInstance';
import { UserAuth } from '../auth/Auth';
import { exportExcel, exportPDF, printTable } from '../../environment/ExportData';
import { customStyles } from '../../environment/Datatable';
import Loader from '../../loader/Loader';
import { Link } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import Select from 'react-select'
import CreateHelpTicket from './CreateHelpTicket';

import EditHelpTicket from './EditHelpTicket';
import { SuccessMessage } from '../../environment/ToastMessage';
import ViewHelpTicket from './ViewHelpTicket';
import HelpTicketMsg from './HelpTicketMsg';
import moment from 'moment';





function RaisedByMe() {

    const { Logout, User } = UserAuth()
    const [loading, setLoading] = useState(false)
    const [raisedByMeTableData, setraisedByMeTableData] = useState([]);
    const [raisedByMeDataAll, setraisedByMeDataAll] = useState([]);

    //========================Get Api Call=============================
    const GetPending = () => {
        setLoading(true)
        PrivateAxios.get('ticket/get-by-pending')
            .then((res) => {
                setLoading(false)
                setraisedByMeTableData(res.data.data)
                setraisedByMeDataAll(res.data.data)
            }).catch((err) => {
                setLoading(false)
                if (err.response.status == 401) {
                    Logout();
                }

            })
    }
    const GetResolve = () => {
        setLoading(true)
        PrivateAxios.get('ticket/get-by-resolve')
            .then((res) => {
                setLoading(false)
                setraisedByMeTableData(res.data.data)
                setraisedByMeDataAll(res.data.data)
            }).catch((err) => {
                setLoading(false)
                if (err.response.status == 401) {
                    Logout();
                }

            })
    }
    const GetCancel = () => {
        setLoading(true)
        PrivateAxios.get('ticket/get-by-close')
            .then((res) => {
                setLoading(false)
                setraisedByMeTableData(res.data.data)
                setraisedByMeDataAll(res.data.data)
            }).catch((err) => {
                setLoading(false)
                if (err.response.status == 401) {
                    Logout();
                }

            })
    }

    useEffect(() => {
        GetPending();
    }, [])

    //=====================Tab==============================
    const [activeTab, setActiveTab] = useState('pending');
    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
        if (tabId == "pending") {
            GetPending();
        }
        if (tabId == "resolve") {
            GetResolve();
        }
        if (tabId == "close") {
            GetCancel();
        }

    };

    function getUserNameById(userId) {
        const user = User.find(data => data.id == userId);
        return user ? user.name : "";
    }

    // data table start
    const raisedByMeColumns = [
        {
            name: 'Sl No.',
            selector: (row, index) => index + 1,
            width: "70px"
        },
        {
            name: 'Ticket No',
            selector: (row) => row.id,
            width: "130px",
        },
        {
            name: "Created Date",
            selector: (row) => moment(row.created_at).format('DD-MM-YYYY hh:mm A'),
            sortable: true,
            width: "200px"
        },
        {
            name: "Resolve Date",
            selector: (row) => row.resolve_date ? moment(row.resolve_date).format('DD-MM-YYYY | hh:mm A') : "",
            omit: activeTab == "resolve" ? false : true,
            sortable: true,
            width: "200px"
        },
        {
            name: "Closed Date",
            selector: (row) => activeTab == "close" ? moment(row.closed_date).format('DD-MM-YYYY | hh:mm A') : "",
            omit: activeTab == "close" ? false : true,
            sortable: true,
            width: "200px"
        },
        {
            name: "Doer",
            selector: (row) => getUserNameById(row.assign_to),
            sortable: true,
            //reorder: true,
            width: "220px",
            cell: (row) => (
                <button className="table-data-change-btn">
                    {row.assign_to && User && User.map((item) => (
                        item.id == row.assign_to ?
                            < div className="profile-wrap" key={item.id}>
                                <div className="exp-avtar bg-exp-yellow">
                                    {item.user_photo != null ?
                                        < img className="prof-img" src={url + item.user_photo} alt="user" /> :
                                        <span>{item.name != '' ? item.name.split(' ').length > 1 ? item.name.split(' ')[0].split('')[0] + item.name.split(' ')[1].split('')[0] : item.name.split('')[0] + item.name.split('')[1] : ""}</span>
                                    }
                                </div>
                                <div className="ps-2 profile-name-wrap text-truncate">
                                    <h5 className="profile-name text-nowrap text-truncate">{item.name}</h5>
                                </div>
                            </div> : ""
                    ))
                    }
                </button>

            ),
        },
        {
            name: "Subject",
            selector: (row) => row.subject,
            sortable: true,
            width: "350px",
        },
        {
            name: "Status",
            selector: (row) => row.status == 0 ? "Pending" : row.status == 1 ? "Resolve" : row.status == 2 ? "Close" : "",
            sortable: true,
            width: "150px",
            cell: (row) => (
                <>
                    {row.status == 0 ?
                        <div className="status-item todo">
                            <i className="far fa-dot-circle me-2"></i>
                            <span>Pending</span>
                        </div> :
                        row.status == 1 ?
                            <div className="status-item todo">
                                <i className="fas fa-check-circle me-2"></i>
                                <span>Resolve</span>
                            </div> : row.status == 2 ?
                                <div className="status-item todo">
                                    <i className="far fa-times-circle me-2"></i>
                                    <span>Close</span>
                                </div> : ""
                    }
                </>
            ),
        },
        {
            name: "Action",
            width: "170px",
            selector: (row) => null,
            cell: (row) => (
                <div className="d-flex">
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip>
                                View
                            </Tooltip>
                        }
                    >
                        <button className="me-1 table-action-btn" onClick={() => handleShowViewHelpDeskModal(row)}>
                            <i class="fas fa-eye"></i>
                        </button>
                    </OverlayTrigger>
                    {activeTab == "pending" ?
                        <>
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip>
                                        Edit
                                    </Tooltip>
                                }
                            >
                                <button className="me-1 table-action-btn" onClick={() => handleShowEditHelpDeskModal(row)}>
                                    <i class="fas fa-pen"></i>
                                </button>
                            </OverlayTrigger>
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip>
                                        Cancel
                                    </Tooltip>
                                }
                            >
                                <button className="me-1 table-action-btn" onClick={() => stopModalShow(row)}>
                                    <i class="far fa-times-circle text-exp-red"></i>
                                </button>
                            </OverlayTrigger>
                        </> : ""
                    }
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip>
                                Chat
                            </Tooltip>
                        }
                    >
                        <button className="me-1 table-action-btn" onClick={() => handleShowChatWrap(row)}>
                            <i class="fas fa-comment-dots"></i>
                        </button>
                    </OverlayTrigger>

                </div>
            ),
        },
    ];

    // data table end


    //=========================chat cnavas===========================
    const [showChatWrap, setShowChatWrap] = useState(false);
    const [showChatData, setShowChatData] = useState('');
    const handleCloseChatWrap = () => {
        setShowChatWrap(false);
    }
    const handleShowChatWrap = (data) => {
        setShowChatWrap(true);
        setShowChatData(data)
    }
    // for Doer selection    
    const [showDoer, setShowDoer] = useState(false);
    const handleCloseDoer = () => setShowDoer(false);
    const handleShowDoer = () => setShowDoer(true);
    // for Doer selection    
    const [taskDoneShow, setTaskDoneShow] = useState(false);
    const taskDoneModalClose = () => setTaskDoneShow(false);
    const taskDoneModalShow = () => setTaskDoneShow(true);

    //======================task Stop modal=======================
    const [stopShow, setStopModalShow] = useState(false);
    const [stopValue, setStopModalValue] = useState('');
    const [remark, setRemark] = useState('');
    const stopModalClose = () => {
        setStopModalShow(false);
        setStopModalValue('')
        setRemark('')
    }
    const stopModalShow = (data) => {
        setStopModalShow(true);
        setStopModalValue(data)
    }
    const closeTicket = (e) => {
        e.preventDefault();
        PrivateAxios.post('ticket/close', { remark: remark, id: stopValue.id })
            .then((res) => {
                GetPending();
                SuccessMessage(res.data.msg)
                stopModalClose();
            }).catch((err) => {
                if (err.response.status == 401) {
                    Logout();
                }
            })
    }

    //===================Create Help Desk Modal Start==========================
    const [showCreateHelpDeskModal, setShowCreateHelpDeskModal] = useState(false);
    const handleCloseCreateHelpDeskModal = () => setShowCreateHelpDeskModal(false);
    const handleShowCreateHelpDeskModal = () => setShowCreateHelpDeskModal(true);

    //========================View Help Desk Modal Start=============================
    const [showViewHelpDeskModal, setShowViewHelpDeskModal] = useState(false);
    const [showViewHelpDeskData, setShowViewHelpDeskData] = useState('');
    const handleCloseViewHelpDeskModal = () => {
        setShowViewHelpDeskModal(false);
        setShowViewHelpDeskData('')
    }
    const handleShowViewHelpDeskModal = (data) => {
        setShowViewHelpDeskModal(true);
        setShowViewHelpDeskData(data)
    }

    // ======================for Edit Help Desk Modal Start================================
    const [showEditHelpDeskModal, setShowEditHelpDeskModal] = useState(false);
    const [showEditHelpData, setShowEditHelpData] = useState('');
    const handleCloseEditHelpDeskModal = () => {
        setShowEditHelpDeskModal(false);
        setShowEditHelpData('')
    }
    const handleShowEditHelpDeskModal = (data) => {
        setShowEditHelpDeskModal(true);
        setShowEditHelpData(data)
    }

    // for attachment list action end
    //for Ticket attachment start
    const [ticketAttachments, setTicketAttachments] = useState([]);

    const handleTicketAttachment = (event) => {
        const files = Array.from(event.target.files);
        setTicketAttachments((prevAttachments) => [...prevAttachments, ...files]);
    };

    const removeAttachment = (index) => {
        setTicketAttachments((prevAttachments) =>
            prevAttachments.filter((_, i) => i !== index)
        );
    };

    const getIconClass = (fileType) => {
        if (fileType.startsWith('image/')) return 'fas fa-image';
        if (fileType === 'application/pdf') return 'fas fa-file-pdf';
        if (fileType.includes('word')) return 'fas fa-file-word';
        if (fileType.includes('excel')) return 'fas fa-file-excel';
        if (fileType === 'text/plain') return 'fas fa-file-alt';
        return 'fas fa-file-alt';
    };
    //for Ticket attachment end
    //for tab active

    //for tab active end


    // exportExcel
    const generatePdf = () => {
        exportPDF(raisedByMeColumns, raisedByMeTableData, "Ticket")
    }
    const generateExcel = () => {
        exportExcel(raisedByMeColumns, raisedByMeTableData, "Ticket")
    }
    const generatePrint = () => {
        printTable(raisedByMeColumns, raisedByMeTableData, "Ticket")
    }

    const handleKeyUpSearch = (e) => {
        const filteredItems = raisedByMeDataAll.filter((item) => {
            return item && item.subject && item.subject.toLowerCase().replace(/\s+/g, '').includes(e.target.value.toLowerCase().replace(/\s+/g, ''))
        }
        );
        setraisedByMeTableData(filteredItems);
    }

    //====================Multi Search==========================
    //======================================================================
    const [open, setOpen] = useState(false);
    const [searchData, setSearchData] = useState({
        "id": "",
        "assigned_by": "",
        "subject": "",
        "assign_to": "",
    })
    const [task, setTask] = useState({
        startData: "",
        endDate: ""
    })
    const SearchDataButton = (e) => {
        e.preventDefault();
        const filterData = raisedByMeDataAll.filter((item) => {
            console.log(item, "item====");

            return Object.keys(searchData).every(key => {
                const searchValue = searchData[key];
                const itemValue = item[key];

                if (!searchValue) return true;
                if (itemValue === undefined || itemValue === null) return false;
                if (typeof itemValue === 'string') {
                    return itemValue.toLowerCase().replace(/\s+/g, '').includes(searchValue.toLowerCase().replace(/\s+/g, ''));
                }

                if (typeof itemValue === 'number') {
                    return itemValue.toString().replace(/\s+/g, '').includes(searchValue.toString().replace(/\s+/g, ''));
                }
                return false;
            }) && (
                    (!task.startData || new Date(item.created_at) >= new Date(task.startData)) &&
                    (!task.endDate || new Date(item.created_at) <= new Date(task.endDate))
                );
        })
        setraisedByMeTableData(filterData);
    }

    const ClearAll = () => {
        setSearchData({
            "name": "",
            "assign_to": "",
            "task_priority_id": "",
            "task_priority_id": "",
        });
        setTask({
            startData: "",
            endDate: ""
        })
        setraisedByMeTableData(raisedByMeDataAll);
    }

    //dark theme select
    const [isDarkTheme, setIsDarkTheme] = useState(false);

    // Check for the theme by looking at the body's class list
    useEffect(() => {
        const checkTheme = () => {
            setIsDarkTheme(document.body.classList.contains("dark-theme"));
        };

        // Initial check
        checkTheme();

        // MutationObserver to detect theme changes on body
        const observer = new MutationObserver(checkTheme);
        observer.observe(document.body, { attributes: true, attributeFilter: ["class"] });

        // Clean up observer on component unmount
        return () => observer.disconnect();
    }, []);
    //dark theme select end
    return (

        <React.Fragment>
            {loading ? <Loader /> :
                <>
                    <div className="gthh-controller-bar">
                        <ul className="gth-controller-view-block">
                            <li>
                                <Link className="gth-controller-view-item " to="/raised-to-me">
                                    <i className="fas fa-level-down-alt me-2" />
                                    Raised to Me
                                </Link>
                            </li>
                            <li>
                                <Link className="gth-controller-view-item active" to="/raised-by-me">
                                    <i className="fas fa-level-up-alt me-2" />
                                    Raised by Me
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className='bg-white border-bottom mb-4'>
                        <div className="d-flex flex-wrap px-4 py-2 justify-content-between align-items-center">
                            <ul className="nav nav-tabs gth-rounded-tab-2" id="systemControllerFilterTab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button
                                        className={`nav-link ${activeTab === 'pending' ? 'active' : ''}`}
                                        onClick={() => handleTabClick('pending')}
                                        type="button"
                                        role="tab"
                                        aria-controls="pending"
                                        aria-selected={activeTab === 'pending'}
                                    >
                                        <span className="btn-todo"><i className="far fa-dot-circle me-1"></i>Pending</span>
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button
                                        className={`nav-link ${activeTab === 'resolve' ? 'active' : ''}`}
                                        onClick={() => handleTabClick('resolve')}
                                        type="button"
                                        role="tab"
                                        aria-controls="resolve"
                                        aria-selected={activeTab === 'resolve'}
                                    >
                                        <span className="btn-completed"><i className="fas fa-check-circle me-1"></i>Resolve</span>
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button
                                        className={`nav-link ${activeTab === 'close' ? 'active' : ''}`}
                                        onClick={() => handleTabClick('close')}
                                        type="button"
                                        role="tab"
                                        aria-controls="close"
                                        aria-selected={activeTab === 'close'}
                                    >
                                        <span className="btn-closeStop"><i className="far fa-times-circle me-1"></i>Cancel</span>
                                    </button>
                                </li>
                            </ul>
                            <div className='d-flex my-1 ms-auto gap-2'>
                                <button className="btn btn-exp-green btn-sm" onClick={handleShowCreateHelpDeskModal}>
                                    <i className="fas fa-plus me-2"></i>Raise Ticket
                                </button>
                                <button className="btn btn-exp-info btn-sm" onClick={() => { setOpen(!open); ClearAll() }} data-bs-toggle="collapse" href="#contentId" aria-expanded="false" aria-controls="contentId" >
                                    <i className="fas fa-filter me-2"></i>Filter
                                </button>
                            </div>
                        </div>
                        <div className='w-100'>
                            <div className="collapse px-4 py-2" id="contentId">
                                <form id='search-form'>
                                    <div className='card'>
                                        <div className='card-header'>
                                            <h5 className='card-title font-weight-medium'>
                                                Search Help Ticket
                                            </h5>
                                        </div>

                                        <div className='card-body pb-1'>
                                            <div className='row'>
                                                <div className='col-lg-3 col-md-4 col-sm-6 col-12'>
                                                    <div className='form-group'>
                                                        <label className='form-label'>Assignee</label>
                                                        <div className='custom-select-wrap'>
                                                            <Select
                                                                name='selectDoer'
                                                                options={User}
                                                                getOptionLabel={(option) => option.name}
                                                                getOptionValue={(option) => option.id}
                                                                value={User.find(data => data.id == searchData.assigned_by) || ''}
                                                                onChange={(e) => { setSearchData({ ...searchData, assigned_by: e.id }) }}
                                                                theme={(theme) => ({
                                                                    ...theme,
                                                                    colors: {
                                                                        ...theme.colors,
                                                                        primary25: '#ddddff',
                                                                        primary: '#6161ff',
                                                                    },
                                                                })}
                                                                styles={{
                                                                    menu: (base) => ({
                                                                        ...base,
                                                                        backgroundColor: isDarkTheme ? "#06162f" : "#ffffff",
                                                                        color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                                    }),
                                                                    singleValue: (base) => ({
                                                                        ...base,
                                                                        color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                                    }),
                                                                    option: (base, state) => ({
                                                                        ...base,
                                                                        backgroundColor: state.isFocused ? (isDarkTheme ? "#112a46" : "#ddddff") : "transparent",
                                                                        color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                                    }),
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-lg-3 col-md-4 col-sm-6 col-12'>
                                                    <div className='form-group'>
                                                        <label className='form-label'>Doer</label>
                                                        <div className='custom-select-wrap'>
                                                            <Select
                                                                name='selectDoer'
                                                                options={User}
                                                                getOptionLabel={(option) => option.name}
                                                                getOptionValue={(option) => option.id}
                                                                value={User.find(data => data.id == searchData.assign_to) || ''}
                                                                onChange={(e) => { setSearchData({ ...searchData, assign_to: e.id }) }}
                                                                theme={(theme) => ({
                                                                    ...theme,
                                                                    colors: {
                                                                        ...theme.colors,
                                                                        primary25: '#ddddff',
                                                                        primary: '#6161ff',
                                                                    },
                                                                })}
                                                                styles={{
                                                                    menu: (base) => ({
                                                                        ...base,
                                                                        backgroundColor: isDarkTheme ? "#06162f" : "#ffffff",
                                                                        color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                                    }),
                                                                    singleValue: (base) => ({
                                                                        ...base,
                                                                        color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                                    }),
                                                                    option: (base, state) => ({
                                                                        ...base,
                                                                        backgroundColor: state.isFocused ? (isDarkTheme ? "#112a46" : "#ddddff") : "transparent",
                                                                        color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                                    }),
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-lg-3 col-md-4 col-sm-6 col-12'>
                                                    <div className='form-group'>
                                                        <label className='form-label'>Ticket Number</label>
                                                        <input
                                                            type='text'
                                                            value={searchData.id}
                                                            className='form-control'
                                                            placeholder='Enter Ticket Number'
                                                            onChange={(e) => setSearchData({ ...searchData, id: e.target.value })}
                                                        />
                                                    </div>
                                                </div>

                                                <div className='col-lg-3 col-md-4 col-sm-6 col-12'>
                                                    <div className='form-group'>
                                                        <label className='form-label'>Subject</label>
                                                        <input
                                                            type='text'
                                                            value={searchData.subject}
                                                            className='form-control'
                                                            placeholder='Enter Subject'
                                                            onChange={(e) => setSearchData({ ...searchData, subject: e.target.value })}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-lg-3 col-md-4 col-sm-6 col-12'>
                                                    <div className='form-group'>
                                                        <label className='form-label'>From Date</label>
                                                        <div className="exp-datepicker-cont">
                                                            <span className="cal-icon"><i className="fas fa-calendar-alt" /></span>
                                                            <DatePicker
                                                                selected={task.startData} onChange={(date) => setTask({ ...task, startData: date })}
                                                                dateFormat="dd/MM/YYYY"
                                                                placeholderText='Select Date'
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-lg-3 col-md-4 col-sm-6 col-12'>
                                                    <div className='form-group'>
                                                        <label className='form-label'>To Date</label>
                                                        <div className="exp-datepicker-cont">
                                                            <span className="cal-icon"><i className="fas fa-calendar-alt" /></span>
                                                            <DatePicker
                                                                selected={task.endDate} onChange={(date) => setTask({ ...task, endDate: date })}
                                                                dateFormat="dd/MM/YYYY"
                                                                placeholderText='Select Date'
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-footer d-flex justify-content-end">
                                            <button className="btn btn-exp-green" onClick={SearchDataButton}  >Search</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className='gth-dashboard-v2-process-wrap'>
                        <div className="tab-content pt-0">
                            <div className={`tab-pane ${activeTab === 'pending' ? 'active' : ''}`} id="pending" role="tabpanel">
                                <div className='card'>
                                    <div className='card-body p-0'>
                                        {/* <div div className="w-100">
                                        <div className="card bg-warning-light mb-0">
                                            <div className="card-body">
                                                <div className="exp-no-data-found text-exp-red">
                                                    <img className="task-img mb-3" src={process.env.PUBLIC_URL + 'assets/images/search-no-record-found.webp'} alt="No task" />
                                                    <h6>No Record Found</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>  */}
                                        <>
                                            <div className="table-view" >
                                                <div className='d-flex justify-content-between flex-wrap align-items-center pt-3 px-3'>
                                                    <div className='table-button-group mb-3'>
                                                        <button className='btn table-export-btn' onClick={generateExcel} >
                                                            <i className="fas fa-file-csv"></i>
                                                        </button>
                                                        <button className='btn table-export-btn' onClick={generatePdf}>
                                                            <i className="fas fa-file-pdf"></i>
                                                        </button>
                                                        <button className='btn table-export-btn' onClick={generatePrint}>
                                                            <i className="fas fa-print"></i>
                                                        </button>
                                                    </div>
                                                    <div className='d-flex align-items-center mb-3 ms-auto'>
                                                        <label className='mr-2 mb-0'>Search: </label>
                                                        <input type='text' placeholder='Type here...' className='form-control form-control-sm' onChange={handleKeyUpSearch} />
                                                    </div>
                                                </div>

                                                <DataTable
                                                    columns={raisedByMeColumns}
                                                    data={raisedByMeTableData}
                                                    //pagination={[5, 10, 25, 50]}
                                                    pagination={false}
                                                    theme="solarized"
                                                    striped
                                                    className='custom-table-wrap workflow-table-striped'
                                                />
                                            </div>
                                        </>

                                    </div>
                                </div>
                            </div>
                            <div className={`tab-pane ${activeTab === 'resolve' ? 'active' : ''}`} id="resolve" role="tabpanel">
                                <div className='card'>
                                    <div className='card-body p-0'>
                                        {/* <div div className="w-100">
                                        <div className="card bg-warning-light mb-0">
                                            <div className="card-body">
                                                <div className="exp-no-data-found text-exp-red">
                                                    <img className="task-img mb-3" src={process.env.PUBLIC_URL + 'assets/images/search-no-record-found.webp'} alt="No task" />
                                                    <h6>No Record Found</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>  */}
                                        <>
                                            <div className="table-view" >
                                                <div className='d-flex justify-content-between flex-wrap align-items-center pt-3 px-3'>
                                                    <div className='table-button-group mb-3'>
                                                        <button className='btn table-export-btn' onClick={generateExcel} >
                                                            <i className="fas fa-file-csv"></i>
                                                        </button>
                                                        <button className='btn table-export-btn' onClick={generatePdf}>
                                                            <i className="fas fa-file-pdf"></i>
                                                        </button>
                                                        <button className='btn table-export-btn' onClick={generatePrint}>
                                                            <i className="fas fa-print"></i>
                                                        </button>
                                                    </div>
                                                    <div className='d-flex align-items-center mb-3 ms-auto'>
                                                        <label className='mr-2 mb-0'>Search: </label>
                                                        <input type='text' placeholder='Type here...' className='form-control form-control-sm' />
                                                    </div>
                                                </div>

                                                <DataTable
                                                    columns={raisedByMeColumns}
                                                    data={raisedByMeTableData}
                                                    //pagination={[5, 10, 25, 50]}
                                                    pagination={false}
                                                    theme="solarized"
                                                    striped
                                                    className='custom-table-wrap workflow-table-striped'
                                                />
                                            </div>
                                        </>

                                    </div>
                                </div>
                            </div>
                            <div className={`tab-pane ${activeTab === 'close' ? 'active' : ''}`} id="close" role="tabpanel">
                                <div className='card'>
                                    <div className='card-body p-0'>
                                        {/* <div div className="w-100">
                                        <div className="card bg-warning-light mb-0">
                                            <div className="card-body">
                                                <div className="exp-no-data-found text-exp-red">
                                                    <img className="task-img mb-3" src={process.env.PUBLIC_URL + 'assets/images/search-no-record-found.webp'} alt="No task" />
                                                    <h6>No Record Found</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>  */}
                                        <>
                                            <div className="table-view" >
                                                <div className='d-flex justify-content-between flex-wrap align-items-center pt-3 px-3'>
                                                    <div className='table-button-group mb-3'>
                                                        <button className='btn table-export-btn' onClick={generateExcel} >
                                                            <i className="fas fa-file-csv"></i>
                                                        </button>
                                                        <button className='btn table-export-btn' onClick={generatePdf}>
                                                            <i className="fas fa-file-pdf"></i>
                                                        </button>
                                                        <button className='btn table-export-btn' onClick={generatePrint}>
                                                            <i className="fas fa-print"></i>
                                                        </button>
                                                    </div>
                                                    <div className='d-flex align-items-center mb-3 ms-auto'>
                                                        <label className='mr-2 mb-0'>Search: </label>
                                                        <input type='text' placeholder='Type here...' className='form-control form-control-sm' />
                                                    </div>
                                                </div>

                                                <DataTable
                                                    columns={raisedByMeColumns}
                                                    data={raisedByMeTableData}
                                                    //pagination={[5, 10, 25, 50]}
                                                    pagination={false}
                                                    theme="solarized"
                                                    striped
                                                    className='custom-table-wrap workflow-table-striped'
                                                />
                                            </div>
                                        </>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
            <>


                <HelpTicketMsg showChatWrap={showChatWrap} handleCloseChatWrap={handleCloseChatWrap} showChatData={showChatData} activeTab={activeTab} GetCancel={GetCancel} GetResolve={GetResolve} GetPending={GetPending} />
                {/* for doer */}
                <form>
                    <Offcanvas id="ticket-doer-change" className="assignee-canvas" show={showDoer} onHide={handleCloseDoer} placement="end">
                        <Offcanvas.Header closeButton className="d-block">
                            <Offcanvas.Title>Change Doer</Offcanvas.Title>
                            <div className="search-assignee">
                                <span className="search-assignee-icon">
                                    <i class="fas fa-search"></i>
                                </span>
                                <input type="text" className="form-control" placeholder="Search or enter email.." />
                            </div>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <div id="selectAssignee" className="assignee-wrapper assignee-wrapper-body">
                                <div className="user-list-wrap">
                                    <div className="user-item">
                                        <div className="profile-wrap">
                                            <div className="exp-avtar">
                                                <i class="fas fa-user user-icon"></i>
                                            </div>
                                            <div className="ps-2 profile-name-wrap text-truncate">
                                                <h5 className="profile-name text-nowrap text-truncate">Sujit Paul</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="user-item">
                                        <div className="profile-wrap">
                                            <div className="exp-avtar bg-exp-green">
                                                <span>JP</span>
                                            </div>
                                            <div className="ps-2 profile-name-wrap text-truncate">
                                                <h5 className="profile-name text-nowrap text-truncate">Jhon Parker</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="user-item">
                                        <div className="profile-wrap">
                                            <div className="exp-avtar">
                                                <img className="prof-img" src={process.env.PUBLIC_URL + 'assets/images/users/1694671741_1517351722263.jpg'} alt="user" />
                                            </div>
                                            <div className="ps-2 profile-name-wrap text-truncate">
                                                <h5 className="profile-name text-nowrap text-truncate">Subhadeep Chowdhury</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="user-item">
                                        <div className="profile-wrap">
                                            <div className="exp-avtar">
                                                <i class="fas fa-user user-icon"></i>
                                            </div>
                                            <div className="ps-2 profile-name-wrap text-truncate">
                                                <h5 className="profile-name text-nowrap text-truncate">Sujit Paul</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="user-item">
                                        <div className="profile-wrap">
                                            <div className="exp-avtar bg-exp-blue">
                                                <span>MS</span>
                                            </div>
                                            <div className="ps-2 profile-name-wrap text-truncate">
                                                <h5 className="profile-name text-nowrap text-truncate">Moumita Shome</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="user-item">
                                        <div className="profile-wrap">
                                            <div className="exp-avtar">
                                                <img className="prof-img" src={process.env.PUBLIC_URL + 'assets/images/users/1694671598_002.png'} alt="user" />
                                            </div>
                                            <div className="ps-2 profile-name-wrap text-truncate">
                                                <h5 className="profile-name text-nowrap text-truncate">Gopal Mukherjee</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Offcanvas.Body>
                    </Offcanvas>
                </form>
                {/* for doer end */}
                {/* Task Done modal start */}
                <form action=''>
                    <Modal id="ticketDone"
                        show={taskDoneShow}
                        onHide={taskDoneModalClose}
                        backdrop="static"
                        keyboard={false}
                        centered
                    >
                        <Modal.Header closeButton className="gth-blue-light-bg">
                            <Modal.Title className="gth-modal-title">Complete Ticket No: <span>ID00001</span> </Modal.Title>
                        </Modal.Header>

                        <Modal.Body className='pb-1'>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className="form-group">
                                        <label className="form-label">Doer : </label>
                                        <div className="profile-wrap">
                                            <div className="exp-avtar bg-exp-blue">
                                                <span>MS</span>
                                            </div>
                                            <div className="ps-2 profile-name-wrap text-truncate">
                                                <h5 className="profile-name text-nowrap">Moumita Shome</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-12'>
                                    <div className="form-group">
                                        <label className="form-label">Remarks : </label>
                                        <textarea className="form-control" name="remarks" rows="3"></textarea>
                                    </div>
                                </div>
                                <div className='col-12'>
                                    <div className="form-group">
                                        <label className="form-label">File </label>
                                        <div className="custom-file-input">
                                            <input type="file" name='file' className='form-control' />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <button type='submit' className='btn btn-exp-green'>
                                Confirm
                            </button>
                        </Modal.Footer>

                    </Modal>
                </form>
                {/* Task Done modal end */}
                {/* Cancel Ticket modal start */}
                <Modal id="ticket-stop-modal"
                    show={stopShow}
                    onHide={stopModalClose}
                    backdrop="static"
                    keyboard={false}
                    centered
                >
                    <Modal.Header closeButton className="gth-blue-light-bg">
                        <Modal.Title className="gth-modal-title">Cancel Ticket ID: <span>{stopValue && stopValue.id}</span></Modal.Title>
                    </Modal.Header>
                    <form onSubmit={closeTicket}>
                        <Modal.Body className='pb-1'>
                            <div className='row'>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label htmlFor="remarks" className="form-label">Remarks</label>
                                        <textarea value={remark} className="form-control" name="remarks" rows={3} onChange={(e) => setRemark(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className='btn btn-exp-green'>
                                Confirm
                            </button>
                        </Modal.Footer>
                    </form>
                </Modal>
                {/* Cancel Ticket modal end */}

                {/* Create New Help Desk Modal */}
                <CreateHelpTicket GetPending={GetPending} showCreateHelpDeskModal={showCreateHelpDeskModal} handleCloseCreateHelpDeskModal={handleCloseCreateHelpDeskModal} />
                {/* Create New Help Desk Modal End*/}
                {/* View New Help Desk Modal */}
                <ViewHelpTicket showViewHelpDeskModal={showViewHelpDeskModal} handleCloseViewHelpDeskModal={handleCloseViewHelpDeskModal} data={showViewHelpDeskData} />
                {/* <form>
                    <Modal id="view-HelpDesk-modal" show={showViewHelpDeskModal} onHide={handleCloseViewHelpDeskModal} backdrop="static" centered size="xl">
                        <Modal.Header closeButton className="gth-blue-light-bg">
                            <Modal.Title className="gth-modal-title">Ticket No: <span>ID00001</span></Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="pb-0">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label className="form-label">Doer</label>
                                        <div className="profile-wrap">
                                            <div className="exp-avtar bg-exp-blue">
                                                <span>MS</span>
                                            </div>
                                            <div className="ps-2 profile-name-wrap text-truncate">
                                                <h5 className="profile-name text-nowrap">Moumita Shome</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group mb-1">
                                        <label className="form-label">CC</label>
                                        <div className='d-flex flex-wrap cc-wrap-raise-tome'>
                                            <div className="profile-wrap me-3 mb-2">
                                                <div className="exp-avtar bg-exp-purple">
                                                    <span>SC</span>
                                                </div>
                                                <div className="ps-2 profile-name-wrap text-truncate">
                                                    <h5 className="profile-name text-nowrap">Subhadeep Chowdhury</h5>
                                                </div>
                                            </div>
                                            <div className="profile-wrap me-3 mb-2">
                                                <div className="exp-avtar bg-exp-green">
                                                    <span>JP</span>
                                                </div>
                                                <div className="ps-2 profile-name-wrap text-truncate">
                                                    <h5 className="profile-name text-nowrap">Jhon Parker</h5>
                                                </div>
                                            </div>
                                            <div className="profile-wrap me-3 mb-2">
                                                <div className="exp-avtar bg-exp-yellow">
                                                    <span>JP</span>
                                                </div>
                                                <div className="ps-2 profile-name-wrap text-truncate">
                                                    <h5 className="profile-name text-nowrap">Sujit Paul</h5>
                                                </div>
                                            </div>
                                            <div className="profile-wrap me-3 mb-2">
                                                <div className="exp-avtar bg-exp-blue">
                                                    <span>SK</span>
                                                </div>
                                                <div className="ps-2 profile-name-wrap text-truncate">
                                                    <h5 className="profile-name text-nowrap">Sandeep kr Paul</h5>
                                                </div>
                                            </div>
                                            <div className="profile-wrap me-3 mb-2">
                                                <div className="exp-avtar bg-exp-red">
                                                    <span>GM</span>
                                                </div>
                                                <div className="ps-2 profile-name-wrap text-truncate">
                                                    <h5 className="profile-name text-nowrap">Gopal Mukherjee</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label className="form-label">Title</label>
                                        <p className="mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label className="form-label">Message</label>
                                        <p className="mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <div className="card shadow-none border grid-files mt-2 mb-0">
                                            <div className="card-header bg-exp-primary-grey-light-1">
                                                <h6>Files</h6>
                                            </div>
                                            <div className="card-body">
                                                <div className="gth-attachment-body">
                                                    <figure className="gth-attachment-tile-item">
                                                        <div className="grid-action-btn">
                                                            <OverlayTrigger
                                                                trigger="click"
                                                                rootClose
                                                                placement="bottom"
                                                                overlay={attachmentListAction}
                                                            >
                                                                <button className="action-btn">
                                                                    <i class="fas fa-ellipsis-v"></i>
                                                                </button>
                                                            </OverlayTrigger>
                                                        </div>
                                                        <div className="attachment-image">
                                                            <span className="related-icon">
                                                                <i className="fas fa-file-excel" />
                                                            </span>
                                                        </div>
                                                    </figure>
                                                    <figure className="gth-attachment-tile-item">
                                                        <div className="grid-action-btn">
                                                            <OverlayTrigger
                                                                trigger="click"
                                                                rootClose
                                                                placement="bottom"
                                                                overlay={attachmentListAction}
                                                            >
                                                                <button className="action-btn">
                                                                    <i class="fas fa-ellipsis-v"></i>
                                                                </button>
                                                            </OverlayTrigger>
                                                        </div>
                                                        <div className="attachment-image">
                                                            <span className="related-icon">
                                                                <i className="fas fa-file-word" />
                                                            </span>
                                                        </div>
                                                    </figure>
                                                    <figure className="gth-attachment-tile-item">
                                                        <div className="grid-action-btn">
                                                            <OverlayTrigger
                                                                trigger="click"
                                                                rootClose
                                                                placement="bottom"
                                                                overlay={attachmentListAction}
                                                            >
                                                                <button className="action-btn">
                                                                    <i class="fas fa-ellipsis-v"></i>
                                                                </button>
                                                            </OverlayTrigger>
                                                        </div>
                                                        <div className="attachment-image">
                                                            <span className="related-icon">
                                                                <i className="fas fa-image" />
                                                            </span>
                                                        </div>
                                                    </figure>
                                                    <figure className="gth-attachment-tile-item">
                                                        <div className="grid-action-btn">
                                                            <OverlayTrigger
                                                                trigger="click"
                                                                rootClose
                                                                placement="bottom"
                                                                overlay={attachmentListAction}
                                                            >
                                                                <button className="action-btn">
                                                                    <i class="fas fa-ellipsis-v"></i>
                                                                </button>
                                                            </OverlayTrigger>
                                                        </div>
                                                        <div className="attachment-image">
                                                            <span className="related-icon">
                                                                <i className="fas fa-file-pdf" />
                                                            </span>
                                                        </div>
                                                    </figure>
                                                    <figure className="gth-attachment-tile-item">
                                                        <div className="grid-action-btn">
                                                            <OverlayTrigger
                                                                trigger="click"
                                                                rootClose
                                                                placement="bottom"
                                                                overlay={attachmentListAction}
                                                            >
                                                                <button className="action-btn">
                                                                    <i class="fas fa-ellipsis-v"></i>
                                                                </button>
                                                            </OverlayTrigger>
                                                        </div>
                                                        <div className="attachment-image">
                                                            <span className="related-icon">
                                                                <i className="fas fa-file-alt" />
                                                            </span>
                                                        </div>
                                                    </figure>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="btn btn-exp-green" >
                                Create
                            </button>
                        </Modal.Footer>
                    </Modal>
                </form> */}
                {/* View New Help Desk Modal End*/}
                {/* Edit Help Desk Modal */}
                <EditHelpTicket GetPending={GetPending} handleCloseEditHelpDeskModal={handleCloseEditHelpDeskModal} showEditHelpData={showEditHelpData} showEditHelpDeskModal={showEditHelpDeskModal} />
                {/* Edit Help Desk Modal End*/}
            </>

        </React.Fragment >

    )
}

export default RaisedByMe;
import React, { useEffect, useRef, useState } from 'react'
import Form from 'react-bootstrap/Form';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select'
import { Link } from 'react-router-dom';
import { PrivateAxios } from '../../../environment/AxiosInstance';
import FmsDynamicReport from './FmsDynamicReport';
import { useReactToPrint } from 'react-to-print';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { faker } from '@faker-js/faker';
import { Doughnut } from 'react-chartjs-2';
import ColumanChat from './ColumanChat';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);




function FlowReportIndex() {

    const [fms, setFms] = useState([]);
    const [reportname, setReportName] = useState('');
    const GetFms = () => {
        PrivateAxios.get('report/all-fms')
            .then((res) => {
                setFms(res.data.data)
            }).catch((err) => {

            })
    }
    useEffect(() => {
        GetFms();
    }, [])

    //==================All Layout Data=======================/
    //**************************************************/
    const [layoutData1, setLayoutData1] = useState([])

    const [fmsId, setFmsId] = useState();

    const GetFmsFrom = (data) => {
        setFmsId(data.id)
        console.log(data.id);

        PrivateAxios.get(`report/all-form/${data.id}`)
            .then((res) => {
                setLayoutData1(res.data.data)
            }).catch((err) => {

            })
    }

    const contentRef = useRef(null);
    const reactToPrintFn = useReactToPrint({ contentRef });

    //=====================Layout 1========================//
    //******************************************************/
    const [layoutC1, setLayoutC1] = useState({
        "pie": 0,
        "bar": 0,
        "table": 0,
        "operation": ""
    })
    const [table1, setTable1] = useState([]);
    const [chat1, setChat1] = useState([]);
    const [layoutName1, setLayoutName1] = useState('')


    // const uniqueRows1 = [...new Set(table1.map(item => item.row))];
    // const uniqueColumns1 = [...new Set(table1.map(item => item.column))];
    // const uniqueRows1 = [];
    // const uniqueColumns1 = [];

    // console.log(table1, "tablee=============");


    // const tableMatrix1 = uniqueRows1.map(row => {
    //     return uniqueColumns1.map(column => {
    //         const cellData = table1.find(item => item.row === row && item.column === column);
    //         return cellData ? cellData.value : '';
    //     });
    // });

    const data1 = {
        labels: chat1.map(item => item.row),
        datasets: [
            {
                label: `Value`,
                data: chat1.map(item => layoutC1.operation == "Sum" ? item.total_sum : layoutC1.operation == "average" ? item.average_value : layoutC1.operation == "min" ? item.min_value : layoutC1.operation == "max" ? item.max_value : item.value),
                backgroundColor: chat1.map(() => {
                    return `rgb(${Math.floor(Math.random() * 250)}, ${Math.floor(Math.random() * 250)}, ${Math.floor(Math.random() * 256)})`;
                }),
            },
        ],
    };

    // const Filter1 = (e) => {
    //     setFilterData(e.target.value)
    // }

    //=====================Layout 2========================//
    //******************************************************/
    const [layoutC2, setLayoutC2] = useState({
        "pie": 0,
        "bar": 0,
        "table": 0,
        "operation": ""
    })
    const [layoutName2, setLayoutName2] = useState('')
    const [table2, setTable2] = useState([]);
    const [chat2, setChat2] = useState([]);

    // const uniqueRows2 = [...new Set(table2.map(item => item.row))];
    // const uniqueColumns2 = [...new Set(table2.map(item => item.column))];

    // const tableMatrix2 = uniqueRows2.map(row => {
    //     return uniqueColumns2.map(column => {
    //         const cellData = table2.find(item => item.row === row && item.column === column);
    //         return cellData ? cellData.value : '';
    //     });
    // });
    const data2 = {
        labels: chat2.map(item => item.row),
        datasets: [
            {
                label: `Value`,
                data: chat2.map(item => layoutC2.operation == "Sum" ? item.total_sum : layoutC2.operation == "average" ? item.average_value : layoutC2.operation == "min" ? item.min_value : layoutC2.operation == "max" ? item.max_value : item.value),
                backgroundColor: chat2.map(() => {
                    return `rgb(${Math.floor(Math.random() * 250)}, ${Math.floor(Math.random() * 250)}, ${Math.floor(Math.random() * 256)})`;
                }),
            },
        ],
    };

    //=====================Layout 3========================//
    //******************************************************/
    const [layoutC3, setLayoutC3] = useState({
        "pie": 0,
        "bar": 0,
        "table": 0,
        "operation": ""
    })
    const [table3, setTable3] = useState([]);
    const [chat3, setChat3] = useState([]);
    const [layoutName3, setLayoutName3] = useState('')
    // const uniqueRows3 = [...new Set(table3.map(item => item.row))];
    // const uniqueColumns3 = [...new Set(table3.map(item => item.column))];

    // const tableMatrix3 = uniqueRows3.map(row => {
    //     return uniqueColumns1.map(column => {
    //         const cellData = table1.find(item => item.row === row && item.column === column);
    //         return cellData ? cellData.value : '';
    //     });
    // });

    const data3 = {
        labels: chat3.map(item => item.row),
        datasets: [
            {
                label: `Value`,
                data: chat3.map(item => layoutC3.operation == "Sum" ? item.total_sum : layoutC3.operation == "average" ? item.average_value : layoutC3.operation == "min" ? item.min_value : layoutC3.operation == "max" ? item.max_value : item.value),
                backgroundColor: chat3.map(() => {
                    return `rgb(${Math.floor(Math.random() * 250)}, ${Math.floor(Math.random() * 250)}, ${Math.floor(Math.random() * 256)})`;
                }),
            },
        ],
    };

    //=====================Layout 4========================//
    //******************************************************/
    const [layoutC4, setLayoutC4] = useState({
        "pie": 0,
        "bar": 0,
        "table": 0,
        "operation": ""
    })
    const [table4, setTable4] = useState([]);
    const [chat4, setChat4] = useState([]);
    const [layoutName4, setLayoutName4] = useState('')


    // const uniqueRows4 = [...new Set(table4.map(item => item.row))];
    // const uniqueColumns4 = [...new Set(table4.map(item => item.column))];

    // const tableMatrix4 = uniqueRows4.map(row => {
    //     return uniqueColumns4.map(column => {
    //         const cellData = table4.find(item => item.row === row && item.column === column);
    //         return cellData ? cellData.value : '';
    //     });
    // });

    const data4 = {
        labels: chat4.map(item => item.row),
        datasets: [
            {
                label: `Value`,
                data: chat4.map(item => layoutC4.operation == "Sum" ? item.total_sum : layoutC4.operation == "average" ? item.average_value : layoutC4.operation == "min" ? item.min_value : layoutC4.operation == "max" ? item.max_value : item.value),
                backgroundColor: chat4.map(() => {
                    return `rgb(${Math.floor(Math.random() * 250)}, ${Math.floor(Math.random() * 250)}, ${Math.floor(Math.random() * 256)})`;
                }),
            },
        ],
    };
    //dark theme select
    const [isDarkTheme, setIsDarkTheme] = useState(false);

    // Check for the theme by looking at the body's class list
    useEffect(() => {
        const checkTheme = () => {
            setIsDarkTheme(document.body.classList.contains("dark-theme"));
        };

        // Initial check
        checkTheme();

        // MutationObserver to detect theme changes on body
        const observer = new MutationObserver(checkTheme);
        observer.observe(document.body, { attributes: true, attributeFilter: ["class"] });

        // Clean up observer on component unmount
        return () => observer.disconnect();
    }, []);
    //dark theme select end

    return (
        <React.Fragment>
            <div className="p-4" >
                <div className="mb-4 d-flex align-items-center justify-content-betweem">
                    <Link className="text-dark back-btn me-3" to="/reports">
                        <i className="fas fa-long-arrow-alt-left me-1" />Back
                    </Link>
                </div>
                <div className='card mb-4'>
                    <div className="card-header d-flex justify-content-between align-items-center">
                        <h3 className="card-title">FLOW REPORT</h3>
                        <button className='btn btn-secondary btn-sm ms-auto' onClick={reactToPrintFn}>
                            <i className="fas fa-print me-2"></i>
                            Print
                        </button>
                    </div>
                    <div className='card-body pb-1'>
                        <div className='w-100'>
                            <div className='col-12'>
                                <div className='row'>
                                    <div className='col-lg-6 col-md-12 col-12'>
                                        <div className='form-group'>
                                            <label className='form-label'>Work Flow Name <span className='text-exp-red'>*</span></label>
                                            <div className='custom-select-wrap'>
                                                <Select
                                                    name='fms Name'
                                                    options={fms}
                                                    getOptionLabel={(option) => option.name}
                                                    getOptionValue={(option) => option.id}
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#ddddff',
                                                            primary: '#6161ff',
                                                        },
                                                    })}
                                                    onChange={GetFmsFrom}
                                                    styles={{
                                                        menu: (base) => ({
                                                            ...base,
                                                            backgroundColor: isDarkTheme ? "#06162f" : "#ffffff",
                                                            color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                        }),
                                                        singleValue: (base) => ({
                                                            ...base,
                                                            color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                        }),
                                                        option: (base, state) => ({
                                                            ...base,
                                                            backgroundColor: state.isFocused ? (isDarkTheme ? "#112a46" : "#ddddff") : "transparent",
                                                            color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                        }),
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-md-12 col-12'>
                                        <div className='form-group'>
                                            <label className='form-label'>Report Name <span className='text-exp-red'>*</span></label>
                                            <div className='custom-select-wrap multi-select'>
                                                <input type='text' value={reportname} className='form-control' name='text-name' onChange={(e) => setReportName(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <FmsDynamicReport layoutData1={layoutData1} fmsId={fmsId} setLayoutName={setLayoutName1} layout={"layout 1"} setTable={setTable1} setChat={setChat1} setLayoutC={setLayoutC1} layoutC={layoutC1} />
                <FmsDynamicReport layoutData1={layoutData1} fmsId={fmsId} setLayoutName={setLayoutName2} layout={"layout 2"} setTable={setTable2} setChat={setChat2} setLayoutC={setLayoutC2} layoutC={layoutC2} />
                <FmsDynamicReport layoutData1={layoutData1} fmsId={fmsId} setLayoutName={setLayoutName3} layout={"layout 3"} setTable={setTable3} setChat={setChat3} setLayoutC={setLayoutC3} layoutC={layoutC3} />
                <FmsDynamicReport layoutData1={layoutData1} fmsId={fmsId} setLayoutName={setLayoutName4} layout={"layout 4"} setTable={setTable4} setChat={setChat4} setLayoutC={setLayoutC4} layoutC={layoutC4} />

                <div className="row" ref={contentRef}>
                    {reportname ? <div className='col-12'>
                        <div className=''>
                            <h4 className='px-3 py-2 bg-secondary mb-3 rounded-10 text-center fw-bold'>{reportname || ''}</h4>
                        </div>
                    </div> : ""}

                    {/* no data found */}
                    {!table1.length > 0 && !chat1.length > 0 ?
                        <div className="col-6 mt-3">
                            <div className="card bg-warning-light mb-0">
                                <div className="card-body">
                                    <div className="exp-no-data-found text-exp-red">
                                        <img className="task-img mb-3" src={process.env.PUBLIC_URL + 'assets/images/search-no-record-found.webp'} alt="No task" />
                                        <h6>No Record Found</h6>
                                    </div>
                                </div>
                            </div>
                        </div> : ""
                    }
                    {!table2.length > 0 && !chat2.length > 0 ?
                        <div className="col-6 mt-3">
                            <div className="card bg-warning-light mb-0">
                                <div className="card-body">
                                    <div className="exp-no-data-found text-exp-red">
                                        <img className="task-img mb-3" src={process.env.PUBLIC_URL + 'assets/images/search-no-record-found.webp'} alt="No task" />
                                        <h6>No Record Found</h6>
                                    </div>
                                </div>
                            </div>
                        </div> : ""
                    }
                    {!table3.length > 0 && !chat3.length > 0 ?
                        <div className="col-6 mt-3">
                            <div className="card bg-warning-light mb-0">
                                <div className="card-body">
                                    <div className="exp-no-data-found text-exp-red">
                                        <img className="task-img mb-3" src={process.env.PUBLIC_URL + 'assets/images/search-no-record-found.webp'} alt="No task" />
                                        <h6>No Record Found</h6>
                                    </div>
                                </div>
                            </div>
                        </div> : ""
                    }
                    {!table4.length > 0 && !chat4.length > 0 ?
                        <div className="col-6 mt-3">
                            <div className="card bg-warning-light mb-0">
                                <div className="card-body">
                                    <div className="exp-no-data-found text-exp-red">
                                        <img className="task-img mb-3" src={process.env.PUBLIC_URL + 'assets/images/search-no-record-found.webp'} alt="No task" />
                                        <h6>No Record Found</h6>
                                    </div>
                                </div>
                            </div>
                        </div> : ""
                    }
                    {/* no data found end */}
                    {table1.length > 0 || chat1.length > 0 ?
                        <div className='col-lg-6 col-md-12'>
                            <div className='card'>
                                <div className='card-body'>
                                    <h5>{layoutName1 || "Layout 1"}</h5>
                                    {
                                        layoutC1.bar == 1 ?
                                            <div className='overflow-x-scroll'>
                                                <div className='bar-card-report'>
                                                    <Bar data={data1} />
                                                </div>
                                            </div>
                                            : ""
                                    }
                                    {
                                        layoutC1.table == 1 ?
                                            <div className='fixed-table-wrapper height-374' dangerouslySetInnerHTML={{ __html: table1 ? table1 : "" }} >
                                            </div> : ""
                                    }
                                    {
                                        layoutC1.pie == 1 ?
                                            <>
                                                <ColumanChat data={data1} />
                                                {/* <div className='donought-graph-report'>
                                                    <Doughnut data={data} />
                                                </div>
                                                <div className='donought-graph-report'>
                                                    <Doughnut data={data} />
                                                </div> */}
                                            </>
                                            : ""
                                    }
                                </div>
                            </div>
                        </div> : ""
                    }
                    {table2.length > 0 || chat2.length > 0 ?
                        <div className='col-lg-6 col-md-12'>
                            <div className='card'>
                                <div className='card-body'>
                                    <h5>{layoutName2 || "Layout 2"}</h5>
                                    {
                                        layoutC2.bar == 1 ?
                                            <div className='overflow-x-scroll'>
                                                <div className='bar-card-report'>
                                                    <Bar data={data2} />
                                                </div>
                                            </div>
                                            : ""
                                    }
                                    {
                                        layoutC2.table == 1 ?
                                            <div className='fixed-table-wrapper height-374' dangerouslySetInnerHTML={{ __html: table2 ? table2 : "" }}>

                                            </div> : ""
                                    }
                                    {
                                        layoutC2.pie == 1 ?
                                            <>
                                                <ColumanChat data={data2} />
                                                {/* <div className='donought-graph-report'>
                                                    <Doughnut data={data} />
                                                </div>
                                                <div className='donought-graph-report'>
                                                    <Doughnut data={data} />
                                                </div> */}
                                            </>
                                            : ""
                                    }
                                </div>
                            </div>
                        </div> : ""
                    }
                    {table3.length > 0 || chat3.length > 0 ?
                        <div className='col-lg-6 col-md-12'>
                            <div className='card'>
                                <div className='card-body'>
                                    <h5>{layoutName3 || "Layout 3"}</h5>
                                    {
                                        layoutC3.bar == 1 ?
                                            <div className='overflow-x-scroll'>
                                                <div className='bar-card-report'>
                                                    <Bar data={data3} />
                                                </div>
                                            </div>
                                            : ""
                                    }
                                    {
                                        layoutC3.table == 1 ?
                                            <div className='fixed-table-wrapper height-374' dangerouslySetInnerHTML={{ __html: table3 ? table3 : "" }}>


                                            </div> : ""
                                    }
                                    {
                                        layoutC3.pie == 1 ?
                                            <>
                                                <ColumanChat data={data3} />
                                                {/* <div className='donought-graph-report'>
                                                    <Doughnut data={data} />
                                                </div>
                                                <div className='donought-graph-report'>
                                                    <Doughnut data={data} />
                                                </div> */}
                                            </>
                                            : ""
                                    }
                                </div>
                            </div>
                        </div> : ""
                    }


                    {table4.length > 0 || chat4.length > 0 ?
                        <div className='col-lg-6 col-md-12'>
                            <div className='card'>
                                <div className='card-body'>
                                    <h5>{layoutName4 || "Layout 4"}</h5>
                                    {
                                        layoutC4.bar == 1 ?
                                            <div className='overflow-x-scroll'>
                                                <div className='bar-card-report'>
                                                    <Bar data={data4} />
                                                </div>
                                            </div>
                                            : ""
                                    }
                                    {
                                        layoutC4.table == 1 ?
                                            <div className='fixed-table-wrapper height-374' dangerouslySetInnerHTML={{ __html: table4 ? table4 : "" }}>


                                            </div> : ""
                                    }
                                    {
                                        layoutC4.pie == 1 ?
                                            <ColumanChat data={data4} />
                                            : ""
                                    }
                                </div>
                            </div>
                        </div> : ""
                    }


                    {/* <div className='col-lg-6 col-md-12'>
                        <div className='card'>
                            <div className='card-body'>
                                <h5>Layout 3</h5>
                                <div className='donought-graph-report'>
                                    <Doughnut data={data} />
                                </div>
                            </div>
                        </div>
                    </div> */}
                    {/* <div className='col-lg-6 col-md-12'>
                        <div className='card'>
                            <div className='card-body'>
                                <h5>Layout 4</h5>
                                <div className='fixed-table-wrapper height-374'>
                                    <table className='table table-striped mb-0 fixedTable-head'>
                                        <thead>
                                            <tr>
                                                <th style={{ width: '25%' }}>Doer Name</th>
                                                <th style={{ width: '10%' }}><span className='text-nowrap d-block text-center'>To Do</span></th>
                                                <th style={{ width: '10%' }}><span className='text-nowrap d-block text-center'>In-Progress</span></th>
                                                <th style={{ width: '10%' }}><span className='text-nowrap d-block text-center'>Complete</span></th>
                                                <th style={{ width: '10%' }}><span className='text-nowrap d-block text-center'>Pending</span></th>
                                                <th style={{ width: '10%' }}><span className='text-nowrap d-block text-center text-exp-red'>Percentage of Pending task</span></th>
                                                <th style={{ width: '10%' }}><span className='text-nowrap d-block text-center'>Total task</span></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <div className="profile-wrap">
                                                        <div className="exp-avtar bg-exp-yellow">
                                                            <span>SC</span>
                                                        </div>
                                                        <div className="ps-2 profile-name-wrap text-truncate">
                                                            <h5 className="profile-name text-nowrap text-truncate">Subhadeep Chowdhury</h5>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <span className='d-block text-center'>1</span>
                                                </td>
                                                <td>
                                                    <span className='d-block text-center'>1</span>
                                                </td>
                                                <td>
                                                    <span className='d-block text-center'>1</span>
                                                </td>
                                                <td>
                                                    <span className='d-block text-center'>1</span>
                                                </td>
                                                <td>
                                                    <span className='d-block text-center text-exp-red'>10%</span>
                                                </td>
                                                <td>
                                                    <span className='d-block text-center'>4</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className="profile-wrap">
                                                        <div className="exp-avtar bg-exp-blue">
                                                            <span>KB</span>
                                                        </div>
                                                        <div className="ps-2 profile-name-wrap text-truncate">
                                                            <h5 className="profile-name text-nowrap text-truncate">Kaushik Biswas</h5>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <span className='d-block text-center'>1</span>
                                                </td>
                                                <td>
                                                    <span className='d-block text-center'>1</span>
                                                </td>
                                                <td>
                                                    <span className='d-block text-center'>1</span>
                                                </td>
                                                <td>
                                                    <span className='d-block text-center'>1</span>
                                                </td>
                                                <td>
                                                    <span className='d-block text-center text-exp-red'>10%</span>
                                                </td>
                                                <td>
                                                    <span className='d-block text-center'>4</span>
                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div> */}

                </div>

            </div>
        </React.Fragment>
    )
}

export default FlowReportIndex
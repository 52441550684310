import React from 'react'
import { Modal } from 'react-bootstrap'

function AutoModalOn(props) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {props.data.taskname} - {props.header}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    {props.data.link}
                </p>
            </Modal.Body>
        </Modal>
    )
}

export default AutoModalOn
import React, { useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { faker } from '@faker-js/faker';
import WorkflowChart from '../Dashboard/DoerDashboard/WorkflowChart';
import TasktrackerChart from '../Dashboard/DoerDashboard/TasktrackerChart';
import ChecksheetChart from '../Dashboard/DoerDashboard/ChecksheetChart';
import OverallPerformanceWidget from './OverallPerformanceWidget';
import WorkflowChartWidget from './WorkflowChartWidget';
import TasktrackerChartWidget from './TasktrackerChartWidget';
import ChecksheetChartWidget from './ChecksheetChartWidget';
import { UserAuth } from '../auth/Auth';
import { PrivateAxios } from '../../environment/AxiosInstance';
import moment from 'moment';
import GaugeComponent from 'react-gauge-component';
import { Link } from 'react-router-dom';




function PerformanceReport() {
    const { Logout, User } = UserAuth();
    const currentDate = new Date();
    const startDateInput = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
    const endDateInput = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState({
        "doer_id": "",
        "startDate": startDateInput,
        "endDate": endDateInput
    })


    const [taskTrackerChart, setTaskTrackerChart] = useState({
        "todo": 0,
        "inprogress": 0,
        "complete": 0,
        "delay": 0,
        "completeDelay": 0
    })
    const [ChecksheetCharts, setChecksheetCharts] = useState({
        "todo": 0,
        "inprogress": 0,
        "complete": 0,
        "delay": 0,
        "completeDelay": 0
    })
    const [workFlowChart, setworkFlowChart] = useState({
        "todo": 0,
        "inprogress": 0,
        "complete": 0,
        "delay": 0,
        "completeDelay": 0
    })


    const GetTaskTrackerData = (data) => {
        PrivateAxios.post(`report/task-perform/${data.doer_id}`, data)
            .then((res) => {
                console.log(res.data);

                const { TodoTask, InprogressTask, CompleteTask, delay, delayComplete } = res.data;
                setTaskTrackerChart({ ...taskTrackerChart, todo: TodoTask, inprogress: InprogressTask, complete: CompleteTask, delay: delay, completeDelay: delayComplete })

            }).catch((err) => {
                setLoading(false)
                if (err.response.status == 401) {
                    Logout();
                }
            })
    }
    const GetChecksheetData = (data) => {
        PrivateAxios.post(`report/checksheet-perform/${data.doer_id}`, data)
            .then((res) => {
                const { TodoTask, InprogressTask, CompleteTask, delay, delayComplete, } = res.data;
                setChecksheetCharts({ ...ChecksheetCharts, todo: TodoTask, inprogress: InprogressTask, complete: CompleteTask, delay: delay, completeDelay: delayComplete })
            }).catch((err) => {
                setLoading(false)
                if (err.response.status == 401) {
                    Logout();
                }
            })
    }
    const GetWorkFlowData = (data) => {
        setLoading(true)
        PrivateAxios.post(`report/workflow-perform/${data.doer_id}`, data)
            .then((res) => {
                setLoading(false)
                const {  TodoTask, InprogressTask, CompleteTask, delay, delayComplete } = res.data;
                setworkFlowChart({ ...workFlowChart, todo: TodoTask, inprogress: InprogressTask, complete: CompleteTask, delay: delay, completeDelay: delayComplete })
            }).catch((err) => {
                setLoading(false)
                if (err.response.status == 401) {
                    Logout();
                }
            })
    }

    const GetPerformance = (date) => {
        PrivateAxios.post(`report/performance/${data.doer_id}`, data)
            .then((res) => {
                setTargetValue(res.data.data)
                console.log(res.data.data)
            }).catch((err) => {
                setLoading(false)
                if (err.response.status == 401) {
                    Logout();
                }
            })
    }


    const SubmitData = (e) => {
        e.preventDefault();
        GetTaskTrackerData(data)
        GetPerformance(data)
        GetChecksheetData(data)
        GetWorkFlowData(data)

    }

    const [gaugeValue, setGaugeValue] = useState(0);
    const [targetValue, setTargetValue] = useState(0)
    useEffect(() => {
        let currentValue = 0;
        const animationStep = 1;
        const interval = setInterval(() => {
            if (currentValue >= targetValue) {
                clearInterval(interval);
            } else {
                currentValue += animationStep;
                setGaugeValue(currentValue);
            }
        }, 20);

        return () => clearInterval(interval);
    }, [targetValue]);



    //dark theme select
    const [isDarkTheme, setIsDarkTheme] = useState(false);

    // Check for the theme by looking at the body's class list
    useEffect(() => {
        const checkTheme = () => {
            setIsDarkTheme(document.body.classList.contains("dark-theme"));
        };

        // Initial check
        checkTheme();

        // MutationObserver to detect theme changes on body
        const observer = new MutationObserver(checkTheme);
        observer.observe(document.body, { attributes: true, attributeFilter: ["class"] });

        // Clean up observer on component unmount
        return () => observer.disconnect();
    }, []);
    //dark theme select end


    return (
        <>
            <div className="p-4">
                <div className="mb-4 d-flex align-items-center justify-content-betweem">
                    <Link className="text-dark back-btn me-3" to="/reports">
                        <i className="fas fa-long-arrow-alt-left me-1" />Back
                    </Link>
                </div>
                <form className='card mb-4' onSubmit={SubmitData}>
                    <div className="card-header">
                        <h3 className="card-title">Performance Report</h3>
                    </div>
                    <div className='card-body pb-1'>
                        <div className='w-100'>
                            <div className='row'>
                                <div className='col-lg-4 col-md-4 col-sm-6 col-12'>
                                    <div className='form-group'>
                                        <label className='form-label'>Doer Name <span className='text-exp-red'>*</span></label>
                                        <div className='custom-select-wrap'>
                                            <Select
                                                required
                                                options={User}
                                                getOptionLabel={(option) => option.name}
                                                getOptionValue={(option) => option.id}
                                                theme={(theme) => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary25: '#ddddff',
                                                        primary: '#6161ff',
                                                    },
                                                })}
                                                onChange={(item) => setData({ ...data, doer_id: item.id })}
                                                styles={{
                                                    menu: (base) => ({
                                                        ...base,
                                                        backgroundColor: isDarkTheme ? "#06162f" : "#ffffff",
                                                        color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                    }),
                                                    singleValue: (base) => ({
                                                        ...base,
                                                        color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                    }),
                                                    option: (base, state) => ({
                                                        ...base,
                                                        backgroundColor: state.isFocused ? (isDarkTheme ? "#112a46" : "#ddddff") : "transparent",
                                                        color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                    }),
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-md-4 col-sm-6 col-12'>
                                    <div className='form-group'>
                                        <label className='form-label'>Form <span className='text-exp-red'>*</span></label>
                                        <div className="exp-datepicker-cont">
                                            <span className="cal-icon"><i class="fas fa-calendar-alt" /></span>
                                            <DatePicker
                                                required
                                                selected={data.startDate}
                                                onChange={(item) => setData({ ...data, startDate: new Date(item) })}
                                                dateFormat="dd/MM/YYYY"
                                                placeholderText='Select Date'
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-md-4 col-sm-6 col-12'>
                                    <div className='form-group'>
                                        <label className='form-label'>To <span className='text-exp-red'>*</span></label>
                                        <div className="exp-datepicker-cont">
                                            <span className="cal-icon"><i class="fas fa-calendar-alt" /></span>
                                            <DatePicker
                                                required
                                                selected={data.endDate}
                                                onChange={(item) => setData({ ...data, endDate: new Date(item) })}
                                                dateFormat="dd/MM/YYYY"
                                                placeholderText='Select Date'
                                            />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="card-footer d-flex justify-content-end">
                        <button type="reset" name="reset_button" className="btn btn-exp-light me-2">Reset</button>
                        <button type="submit" name="submit_button" className="btn btn-exp-green" >Generate Report</button>
                    </div>
                </form>
                <div className="row">
                    {/* no data found */}
                    <div className='col-12'>
                        <div className='card mb-0'>
                            <div className='card-header'>
                                <h5 className='card-title'>{data.doer_id ? `${User.find(item => item.id == data.doer_id)?.name}(${User.find(item => item.id == data.doer_id)?.phone_number}) ${data.startDate ? moment(data.startDate).format('DD-MM-YYYY') : ""} ${data.startDate && data.endDate ? 'to' : ""} ${data.endDate ? moment(data.endDate).format('DD-MM-YYYY') : ""}` : ""}</h5>
                            </div>
                            <div className='card-body'>
                                <div className='row flex-nowrap overflow-x-scroll'>
                                    <div className='col-lg-3 col-md-6 col-sm-12 col-12 top-card mb-4'>
                                        <div className='card graph-card h-100 mb-0'>
                                            <div className='card-body'>
                                                <div id='preview'>
                                                    <div className='overall-performance-wrap text-center'>
                                                        <h5 className='card-title mb-2'>Overall Performance</h5>
                                                        <div className='text-center'>
                                                            <GaugeComponent
                                                                value={gaugeValue}
                                                                type="radial"
                                                                style={{
                                                                    width: '100%',
                                                                    height: 'fit-content',
                                                                    margin: '0 auto',
                                                                    minWidth: '200px',
                                                                    maxWidth: '320px'
                                                                }}
                                                                labels={{
                                                                    tickLabels: {
                                                                        type: "outer",
                                                                        ticks: [
                                                                            { value: 20 },
                                                                            { value: 40 },
                                                                            { value: 60 },
                                                                            { value: 80 },
                                                                            { value: 100 }
                                                                        ]
                                                                    },
                                                                }}
                                                                arc={{
                                                                    colorArray: ['#5BE12C', '#EA4228'],
                                                                    subArcs: [{ limit: 10 }, { limit: 30 }, {}, {}, {}],
                                                                    width: 0.3,
                                                                    padding: 0,
                                                                    cornerRadius: 0,
                                                                }}
                                                                pointer={{
                                                                    elastic: true,
                                                                    animationDelay: 0
                                                                }}
                                                            />
                                                        </div>
                                                        <div className='d-flex justify-content-center align-items-center'>
                                                            {/* <h5 className='my-1 me-3 text-muted'>Negative </h5>  */}
                                                            <h4 className='mb-0 fs-2 fw-bold'>Negative {gaugeValue}%</h4>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-3 col-md-6 col-sm-6 col-12 top-card mb-4' id='fms_chart_div_inner'>
                                        <WorkflowChartWidget workFlowChart={workFlowChart} />
                                    </div>
                                    <div className='col-lg-3 col-md-6 col-sm-6 col-12 top-card mb-4' id='delegation_chart_div_inner'>
                                        <TasktrackerChartWidget taskTrackerChart={taskTrackerChart} />
                                    </div>
                                    <div className='col-lg-3 col-md-6 col-sm-6 col-12 top-card mb-4' id='checklist_chart_div_inner'>
                                        <ChecksheetChartWidget ChecksheetCharts={ChecksheetCharts} />
                                    </div>
                                </div>
                                <div className='row'>
                                    {/* <div className="col-12">
                                        <div className="card bg-warning-light mb-0">
                                            <div className="card-body">
                                                <div className="exp-no-data-found text-exp-red">
                                                    <img className="task-img mb-3" src={process.env.PUBLIC_URL + 'assets/images/search-no-record-found.webp'} alt="No task" />
                                                    <h6>No Record Found</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className='col-12'>
                                        <div className="card mb-0">
                                            <div className="card-body p-0">
                                                <div className='d-flex justify-content-between flex-wrap align-items-center pt-3 px-3'>
                                                    <div className='table-button-group mb-3'>
                                                        <button className='btn table-export-btn' >
                                                            <i className="fas fa-file-csv"></i>
                                                        </button>
                                                        <button className='btn table-export-btn'>
                                                            <i className="fas fa-file-pdf"></i>
                                                        </button>
                                                        <button className='btn table-export-btn'>
                                                            <i className="fas fa-print"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className='table-responsive'>
                                                    <table
                                                        className="table table-bordered"
                                                    >
                                                        <tbody>
                                                            <tr>
                                                                <th>
                                                                    <strong> Checksheet Score</strong>
                                                                </th>
                                                                <th

                                                                    className="text-center bg-primary-200"
                                                                >
                                                                    Planned Task
                                                                </th>
                                                                <th
                                                                    className="text-center bg-success-200"
                                                                >
                                                                    Completed Task
                                                                </th>
                                                                <th
                                                                    className="text-center bg-warning-200"
                                                                >
                                                                    Delayed Task
                                                                </th>
                                                                <th
                                                                    className="text-center bg-warning-200"
                                                                >
                                                                    Delayed Complete
                                                                </th>
                                                                <th
                                                                    className="text-center bg-error-200"
                                                                >
                                                                    % Delay
                                                                </th>
                                                                <th
                                                                    className="text-center bg-error-200"
                                                                >
                                                                    % Not Completed
                                                                </th>
                                                            </tr>
                                                            <tr className="odd">
                                                                <td>All work should be done</td>
                                                                <td className="text-center">{ChecksheetCharts.todo + ChecksheetCharts.complete + ChecksheetCharts.inprogress + ChecksheetCharts.delay + ChecksheetCharts.completeDelay}</td>
                                                                <td className="text-center">{ChecksheetCharts.complete}</td>
                                                                <td className="text-center">{ChecksheetCharts.delay}</td>
                                                                <td className="text-center">{ChecksheetCharts.completeDelay}</td>
                                                                <td className="text-center">{(((ChecksheetCharts.delay + ChecksheetCharts.completeDelay) / (ChecksheetCharts.todo + ChecksheetCharts.complete + ChecksheetCharts.inprogress + ChecksheetCharts.delay + ChecksheetCharts.completeDelay)) * 100).toFixed(2)}</td>
                                                                <td className="text-center">{((((ChecksheetCharts.todo + ChecksheetCharts.complete + ChecksheetCharts.inprogress + ChecksheetCharts.delay + ChecksheetCharts.completeDelay) - (ChecksheetCharts.complete + ChecksheetCharts.completeDelay)) / (ChecksheetCharts.todo + ChecksheetCharts.complete + ChecksheetCharts.inprogress + ChecksheetCharts.delay + ChecksheetCharts.completeDelay)) * 100).toFixed(2)}</td>
                                                            </tr>
                                                            <tr className="odd">
                                                                <th nowrap="">Task Tracker Score</th>
                                                                <th nowrap="" className="text-center bg-primary-200">
                                                                    Planned Task
                                                                </th>
                                                                <th nowrap="" className="text-center bg-success-200">
                                                                    Completed Task
                                                                </th>
                                                                <th nowrap="" className="text-center bg-warning-200">
                                                                    Delayed Task
                                                                </th>
                                                                <th
                                                                    className="text-center bg-warning-200"
                                                                >
                                                                    Delayed Complete
                                                                </th>
                                                                <th
                                                                    className="text-center bg-error-200"
                                                                >
                                                                    % Pending Delay
                                                                </th>
                                                                <th nowrap="" className="text-center bg-error-200">
                                                                    % Not Completed
                                                                </th>
                                                            </tr>

                                                            <tr className="odd">
                                                                <td>All work should be done</td>
                                                                <td className="text-center">{taskTrackerChart.todo + taskTrackerChart.complete + taskTrackerChart.inprogress + taskTrackerChart.delay + taskTrackerChart.completeDelay}</td>
                                                                <td className="text-center">{taskTrackerChart.complete}</td>
                                                                <td className="text-center">{taskTrackerChart.delay}</td>
                                                                <td className="text-center">{taskTrackerChart.completeDelay}</td>
                                                                <td className="text-center">{(((taskTrackerChart.delay + taskTrackerChart.completeDelay) / (taskTrackerChart.todo + taskTrackerChart.complete + taskTrackerChart.inprogress + taskTrackerChart.delay + taskTrackerChart.completeDelay)) * 100).toFixed(2)}</td>
                                                                <td className="text-center">{((((taskTrackerChart.todo + taskTrackerChart.complete + taskTrackerChart.inprogress + taskTrackerChart.delay + taskTrackerChart.completeDelay) - (taskTrackerChart.complete + taskTrackerChart.completeDelay)) / (taskTrackerChart.todo + taskTrackerChart.complete + taskTrackerChart.inprogress + taskTrackerChart.delay + taskTrackerChart.completeDelay)) * 100).toFixed(2)}</td>
                                                            </tr>
                                                            <tr className="even">
                                                                <th nowrap="">Work Flow Score</th>
                                                                <th nowrap="" className="text-center bg-primary-200">
                                                                    Planned Task
                                                                </th>
                                                                <th nowrap="" className="text-center bg-success-200">
                                                                    Completed Task
                                                                </th>
                                                                <th nowrap="" className="text-center bg-warning-200">
                                                                    Delayed Task
                                                                </th>
                                                                <th
                                                                    className="text-center bg-warning-200"
                                                                >
                                                                    Delayed Complete
                                                                </th>
                                                                <th
                                                                    className="text-center bg-error-200"
                                                                >
                                                                    % Pending Delay
                                                                </th>
                                                                <th nowrap="" className="text-center bg-error-200">
                                                                    % Not Completed
                                                                </th>
                                                            </tr>
                                                            <tr className="odd">
                                                                <td>All work should be done</td>
                                                                <td className="text-center">{workFlowChart.todo + workFlowChart.complete + workFlowChart.inprogress + workFlowChart.delay + workFlowChart.completeDelay}</td>
                                                                <td className="text-center">{workFlowChart.complete}</td>
                                                                <td className="text-center">{workFlowChart.delay}</td>
                                                                <td className="text-center">{workFlowChart.completeDelay}</td>
                                                                <td className="text-center">{(((workFlowChart.delay + workFlowChart.completeDelay) / (workFlowChart.todo + workFlowChart.complete + workFlowChart.inprogress + workFlowChart.delay + workFlowChart.completeDelay)) * 100).toFixed(2)}</td>
                                                                <td className="text-center">{((((workFlowChart.todo + workFlowChart.complete + workFlowChart.inprogress + workFlowChart.delay + workFlowChart.completeDelay) - (workFlowChart.complete + workFlowChart.completeDelay)) / (workFlowChart.todo + workFlowChart.complete + workFlowChart.inprogress + workFlowChart.delay + workFlowChart.completeDelay)) * 100).toFixed(2)}</td>
                                                            </tr>

                                                        </tbody>
                                                    </table>

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default PerformanceReport
export const convertLinks = (text) => {
    const urlPattern = /\b(http:\/\/|https:\/\/|www\.)[^\s]+/gi;
    const htmlTagPattern = /<\/?[^>]+(>|$)/g;  // Pattern to match HTML tags
    let result = [];

    // Function to strip HTML tags
    const stripHtmlTags = (content) => {
        return content.replace(htmlTagPattern, '');
    };

    // Function to parse content and convert URLs to clickable links
    const parseContent = (content) => {
        let parsedContent = [];
        let lastIndex = 0;
        let match;

        // Remove HTML tags before processing URLs
        content = stripHtmlTags(content);

        while ((match = urlPattern.exec(content)) !== null) {
            parsedContent.push(content.slice(lastIndex, match.index));
            const url = match[0].trim();
            const href = url.startsWith('http') ? url : 'http://' + url;
            parsedContent.push(
                <a key={match.index} href={href} target="_blank" rel="noopener noreferrer">
                    {url}
                </a>
            );
            lastIndex = urlPattern.lastIndex;
        }
        parsedContent.push(content.slice(lastIndex));

        return parsedContent;
    };

    // Strip HTML tags from the entire text first
    const strippedText = stripHtmlTags(text);

    // Parse the stripped text content to convert URLs to clickable links
    result = parseContent(strippedText);

    return result;
};


export const convertLinksNewLine = (text) => {
    const urlPattern = /\b(http:\/\/|https:\/\/|www\.)[^\s]+/gi;
    const htmlTagPattern = /<\/?[^>]+(>|$)/g; // Pattern to match HTML tags

    // Function to strip HTML tags
    const stripHtmlTags = (content) => {
        return content.replace(htmlTagPattern, '');
    };

    const parseContent = (content) => {
        let parsedContent = [];
        let lastIndex = 0;
        let match;
        content = stripHtmlTags(content);

        while ((match = urlPattern.exec(content)) !== null) {
            // Add text before the link, followed by a newline
            if (lastIndex !== match.index) {
                parsedContent.push(content.slice(lastIndex, match.index));
                parsedContent.push(<br key={`br-${lastIndex}`} />); // Add a newline before the link
            }

            const url = match[0].trim();
            const href = url.startsWith('http') ? url : 'http://' + url;

            // Add the link itself
            parsedContent.push(
                <a key={match.index} href={href} target="_blank" rel="noopener noreferrer">
                    {url}
                </a>
            );
            lastIndex = urlPattern.lastIndex;
        }
        if (lastIndex < content.length) {
            parsedContent.push(content.slice(lastIndex));
        }
        return parsedContent;
    };
    const strippedText = stripHtmlTags(text);
    return parseContent(strippedText);
};

import React, { useCallback, useEffect, useState } from 'react'
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { PrivateAxios, PrivateAxiosFile } from '../../environment/AxiosInstance';
import { ErrorMessage, SuccessMessage } from '../../environment/ToastMessage';
import { UserAuth } from '../auth/Auth';
import { useNavigate, useParams } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import TaskMessage from '../../component/TaskMessage';
import moment from 'moment';


function MyTaskDone() {
    const [error, setError] = useState({});
    const { isLoading, setIsLoading, Logout } = UserAuth()
    const [task, setTask] = useState('')
    const [taskCloseInputValue, setTaskCloseInputValue] = useState({
        "remark": "",
        "file": "",
        "file_name": "",
        "id": ""
    });
    const navigate = useNavigate();

    const { id } = useParams()
    useEffect(() => {
        const params = jwtDecode(id);
        PrivateAxios.post('task/get-one-task', { id: params.id })
            .then((res) => {
                console.log(res);
                setTask(res.data.data);
                setTaskCloseInputValue({ ...taskCloseInputValue, id: res.data.data.id })
            }).catch((err) => {
                console.log(err);
            })
    }, [])

    const fileUpload = async (e) => {
        const file = e.target.files[0];
        let fileSize = file.size;
        if (Number(fileSize) >= 2097152) {
            setError({ file: "This image in getter than 2MB" })
        } else {
            setTaskCloseInputValue({ ...taskCloseInputValue, file: e.target.files[0], file_name: e.target.files[0].name });
            setError("")
        }
    }

    const SubmitRemark = (e) => {
        setIsLoading(true);
        e.preventDefault();
        let formData = new FormData();
        formData.append('remark', taskCloseInputValue.remark)
        formData.append('file', taskCloseInputValue.file)
        formData.append('file_name', taskCloseInputValue.file_name)
        formData.append('id', taskCloseInputValue.id)
        PrivateAxiosFile.post("task/task-done", formData)
            .then((res) => {
                // TaskData();
                SuccessMessage(res.data.message);
                // TaskDoneHide();
                navigate('/task')
                setIsLoading(false)


            }).catch((err) => {
                console.log(err);
                setIsLoading(false);
                ErrorMessage(err.response.data.error)
            })
    }

    //=============================message modal=======================================
    const [showMessageBox, setShowMessageBox] = useState(false);
    const [msgData, setMsgData] = useState('');

    // const [selectedDoer, setSelectedDoer] = useState('');
    const handleMessageClose = useCallback(() => {
        setShowMessageBox(false);
    }, [msgData, showMessageBox])
    const handleMessageShow = useCallback((data) => {
        setMsgData(data);
        setShowMessageBox(true)
    }, [msgData, showMessageBox]);




    return (
        <>
            <div className='col-12 px-4 py-4'>
                <div className='card'>
                    <div className='card-header'>
                        <div className='d-flex justify-content-between'>
                            <h5 className='card-title font-weight-medium'>
                                {task && task.name}
                            </h5>
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip>
                                        Chat
                                    </Tooltip>
                                }
                            >
                                <button className="me-1 table-action-btn" onClick={() => handleMessageShow(task)}  >
                                    <i className="fas fa-comment-dots"></i>
                                </button>
                            </OverlayTrigger>
                        </div>
                    </div>
                    <form onSubmit={SubmitRemark}>
                        <Modal.Body className='pb-1'>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className="form-group d-flex">
                                        <label className="form-label">Assigned By : </label>
                                        <p className='mb-0'>{task && task.assignedByUser.name}</p>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className="form-group d-flex">
                                        <label className="form-label">Planned Date : </label>
                                        <p className='mb-0'>{task && moment(task.planned_date).format('DD-MM-YYYY hh:mm A')}</p>
                                    </div>
                                </div>
                                <div className='col-12'>
                                    <div className="form-group">
                                        <label className="form-label">Remarks : </label>
                                        <textarea className="form-control" name="remarks" required rows="3" onChange={(e) => setTaskCloseInputValue({ ...taskCloseInputValue, remark: e.target.value })}></textarea>
                                    </div>
                                </div>
                                <div className='col-12'>
                                    <div className="form-group">
                                        <label className="form-label">File : </label>
                                        <input type='file' className="form-control" name="file" accept=".png, .jpg, .jpeg, .pdf, .xls, .xlsx , .doc" onChange={fileUpload} />
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <button type='submit' className='btn btn-exp-primary'>
                                Confirm
                            </button>
                        </Modal.Footer>
                    </form>
                </div>
            </div>
            <TaskMessage
                show={showMessageBox}
                handleClose={handleMessageClose}
                title="Select Doer"
                data={msgData}
                Tab={''}
                GetTodoByTask={''}
                GetinprogressByTask={''}
                GetcompleteByTask={''}
                GetcloseByTask={''}
                onSelectUser={''}
            />
        </>
    )
}

export default MyTaskDone
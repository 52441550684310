// import React, { useEffect, useState } from 'react'
// import { Chart as ChartJS, ArcElement, Legend, Colors } from 'chart.js';
// import { Doughnut } from 'react-chartjs-2';

// ChartJS.register(ArcElement, Legend);

// const optionsChecksheet = {
//     responsive: true,
//     plugins: {
//         legend: {
//             display: true,
//             position: 'bottom',
//             labels: {
//                 color: '#9e9e9e'
//             },
//         },
//         // title: {
//         //     display: true,
//         //     fontSize: 20,
//         //     text: 'Checksheet'
//         // }
//     },
//     cutout: '60%',
// }

// function ChecksheetChart({ ChecksheetCharts }) {

//     const totalTasks = (ChecksheetCharts?.todo || 0) +
//         (ChecksheetCharts?.inprogress || 0) +
//         (ChecksheetCharts?.complete || 0) +
//         (ChecksheetCharts?.tododelay || 0) +
//         (ChecksheetCharts?.inprogressDelay || 0) +
//         (ChecksheetCharts?.completeDelay || 0);

//     const hasData = totalTasks > 0;


//     const data = {
//         labels: ['Todo', 'In Progress', 'Completed', 'Todo Delay', 'In Progress Delay', 'Delay Completed'],
//         datasets: [
//             {
//                 data: [ChecksheetCharts && ChecksheetCharts.todo, ChecksheetCharts && ChecksheetCharts.inprogress, ChecksheetCharts && ChecksheetCharts.complete,  ChecksheetCharts && ChecksheetCharts.tododelay, ChecksheetCharts && ChecksheetCharts.inprogressDelay, ChecksheetCharts && ChecksheetCharts.completeDelay],
//                 backgroundColor: [
//                     'rgb(127, 127, 127)',
//                     'rgb(89, 71, 208)',
//                     'rgb(63, 213, 52)',
//                     'rgb(255, 230, 43)',
//                     'rgb(232, 143, 41)',
//                     'rgb(244, 27, 27)',
//                 ],
//                 borderWidth: 0,
//             },
//         ],
//     };

//     //dark theme image change
//     const [isDarkTheme, setIsDarkTheme] = useState(false);

//     useEffect(() => {
//         // Check for the theme on initial load
//         const currentTheme = document.body.classList.contains("dark-theme");
//         setIsDarkTheme(currentTheme);

//         // Listen for changes to the body's class (if toggling happens outside this component)
//         const observer = new MutationObserver(() => {
//             const updatedTheme = document.body.classList.contains("dark-theme");
//             setIsDarkTheme(updatedTheme);
//         });

//         observer.observe(document.body, { attributes: true, attributeFilter: ["class"] });

//         // Cleanup observer on component unmount
//         return () => observer.disconnect();
//     }, []);
//     //dark theme image change end
//     return (
//         <>
//             <div className='card text-center graph-card h-100 mb-0'>
//                 <div className='card-body'>
//                     <h5 className='card-title mb-2'>Checksheet</h5>
//                     {hasData ?
//                         <div className='chart-wrap'>
//                             <Doughnut options={optionsChecksheet} data={data} />
//                         </div> :
//                         <div className='chart-wrap-nodata'>
//                             <img
//                                 src={
//                                     isDarkTheme
//                                         ? `${process.env.PUBLIC_URL}/assets/images/graph-nodata-dark.png`
//                                         : `${process.env.PUBLIC_URL}/assets/images/graph-nodata.png`
//                                 }
//                                 className="img-fluid"
//                                 alt="no-data"
//                             />
//                         </div>
//                     }
//                 </div>
//             </div>
//         </>
//     )
// }

// export default ChecksheetChart

import React, { useEffect, useState } from 'react';
import { Chart as ChartJS, ArcElement, Legend, Tooltip } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';
import ChartPlaceholder from '../../../loader/ChartPlaceholder';

ChartJS.register(ArcElement, Legend, Tooltip);

const optionsChecksheet = {
    responsive: true,
    plugins: {
        legend: {
            display: true,
            position: 'bottom',
            labels: {
                color: '#9e9e9e',
                align: 'start', 
                usePointStyle: true,
                padding: 10,
            },
        },
        tooltip: {
            callbacks: {
                label: function (tooltipItem) {
                    const dataset = tooltipItem.dataset;
                    const dataIndex = tooltipItem.dataIndex;
                    const value = dataset.data[dataIndex];
                    const label = tooltipItem.label || tooltipItem.raw;
                    return `${value}`;
                },
            },
        },
    },
    cutout: '60%',
};

function ChecksheetChart({ ChecksheetCharts, Loading }) {
    const navigate = useNavigate();

    const totalTasks =
        (ChecksheetCharts?.todo || 0) +
        (ChecksheetCharts?.inprogress || 0) +
        (ChecksheetCharts?.complete || 0) +
        (ChecksheetCharts?.tododelay || 0) +
        (ChecksheetCharts?.inprogressDelay || 0) +
        (ChecksheetCharts?.completeDelay || 0);

    const hasData = totalTasks > 0;

    const data = {
        labels: [
            'Todo',
            'In Progress',
            'Completed',
            'Todo Delay',
            'In Progress Delay',
            'Delay Completed',
        ],
        datasets: [
            {
                data: [
                    ChecksheetCharts?.todo || 0,
                    ChecksheetCharts?.inprogress || 0,
                    ChecksheetCharts?.complete || 0,
                    ChecksheetCharts?.tododelay || 0,
                    ChecksheetCharts?.inprogressDelay || 0,
                    ChecksheetCharts?.completeDelay || 0,
                ],
                backgroundColor: [
                    'rgb(127, 127, 127)',
                    'rgb(89, 71, 208)',
                    'rgb(63, 213, 52)',
                    'rgb(255, 230, 43)',
                    'rgb(232, 143, 41)',
                    'rgb(244, 27, 27)',
                ],
                borderWidth: 0,
            },
        ],
    };

    // Handle click on pie chart to navigate
    const handleChartClick = (event, chartElements) => {
        if (chartElements.length > 0) {
            const index = chartElements[0].index;
            const label = data.labels[index];

            // Navigate based on the clicked segment
            switch (label) {
                case 'Todo':
                    navigate('/checksheet');
                    break;
                case 'In Progress':
                    navigate('/checksheet');
                    break;
                case 'Completed':
                    navigate('/checksheet');
                    break;
                case 'Todo Delay':
                    navigate('/checksheet');
                    break;
                case 'In Progress Delay':
                    navigate('/checksheet');
                    break;
                case 'Delay Completed':
                    navigate('/checksheet');
                    break;
                default:
                    break;
            }
        }
    };

    // Dark theme image change
    const [isDarkTheme, setIsDarkTheme] = useState(false);

    useEffect(() => {
        const currentTheme = document.body.classList.contains('dark-theme');
        setIsDarkTheme(currentTheme);

        const observer = new MutationObserver(() => {
            const updatedTheme = document.body.classList.contains('dark-theme');
            setIsDarkTheme(updatedTheme);
        });

        observer.observe(document.body, { attributes: true, attributeFilter: ['class'] });

        return () => observer.disconnect();
    }, []);

    return (
        <div className="card text-center graph-card h-100 mb-0">
            <div className="card-body">
                <h5 className="card-title mb-2">Checksheet</h5>
                {Loading ?
                    <ChartPlaceholder /> :
                    <>
                        {hasData ? (
                            <div className="chart-wrap">
                                <Doughnut
                                    options={{
                                        ...optionsChecksheet,
                                        onClick: (event, elements) =>
                                            handleChartClick(event, elements),
                                    }}
                                    data={data}
                                />
                            </div>
                        ) : (
                            <div className="chart-wrap-nodata">
                                <img
                                    src={
                                        isDarkTheme
                                            ? `${process.env.PUBLIC_URL}/assets/images/graph-nodata-dark.png`
                                            : `${process.env.PUBLIC_URL}/assets/images/graph-nodata.png`
                                    }
                                    className="img-fluid"
                                    alt="no-data"
                                />
                            </div>
                        )}
                    </>
                }
            </div>
        </div>
    );
}

export default ChecksheetChart;

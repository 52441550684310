import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import Select from 'react-select'
import { UserAuth } from '../auth/Auth';
import { PrivateAxiosFile } from '../../environment/AxiosInstance';
import { SuccessMessage } from '../../environment/ToastMessage';
import Loader from '../../loader/Loader';

function CreateHelpTicket({ showCreateHelpDeskModal, handleCloseCreateHelpDeskModal, GetPending }) {

    const { User, userDetails, Logout } = UserAuth();
    const [loading, setLoading] = useState(false)
    const [ticketAttachments, setTicketAttachments] = useState([]);
    const handleTicketAttachment = (event) => {
        const files = Array.from(event.target.files);
        setTicketAttachments((prevAttachments) => [...prevAttachments, ...files]);
        setHelpValue({ ...helpValue, file: event.target.files[0] })
    }

    // const removeAttachment = (index) => {
    //     setTicketAttachments((prevAttachments) =>
    //         prevAttachments.filter((_, i) => i !== index)
    //     );
    // };

    // const getIconClass = (fileType) => {
    //     if (fileType.startsWith('image/')) return 'fas fa-image';
    //     if (fileType === 'application/pdf') return 'fas fa-file-pdf';
    //     if (fileType.includes('word')) return 'fas fa-file-word';
    //     if (fileType.includes('excel')) return 'fas fa-file-excel';
    //     if (fileType === 'text/plain') return 'fas fa-file-alt';
    //     return 'fi fi-rr-file';
    // };
    const [helpValue, setHelpValue] = useState({
        "assigned_by": userDetails.id,
        "assign_to": "",
        "subject": "",
        "description": "",
        "cc": "",
        "file": ""
    })
    const onSubmit = (e) => {
        e.preventDefault();
        setLoading(true)
        const formData = new FormData();
        if (helpValue.file == "") {
            formData.append("assigned_by", userDetails.id)
            formData.append("assign_to", helpValue.assign_to)
            formData.append("subject", helpValue.subject)
            formData.append("description", helpValue.description)
            formData.append("cc", helpValue.cc)
        } else {
            formData.append("assigned_by", userDetails.id)
            formData.append("assign_to", helpValue.assign_to)
            formData.append("subject", helpValue.subject)
            formData.append("description", helpValue.description)
            formData.append("cc", helpValue.cc)
            formData.append("file", helpValue.file)
        }
        PrivateAxiosFile.post('ticket/create', formData)
            .then((res) => {
                clearAll();
                GetPending();
                SuccessMessage(res.data.msg)
                handleCloseCreateHelpDeskModal();
                setLoading(false)

            }).catch((err) => {
                setLoading(false)
                if (err.response.status == 401) {
                    Logout();
                }
            })

    }
    const clearAll = () => {
        setHelpValue({
            ...helpValue,
            "assign_to": "",
            "subject": "",
            "description": "",
            "cc": "",
            "file": ""
        })
    }
    //dark theme select
    const [isDarkTheme, setIsDarkTheme] = useState(false);

    // Check for the theme by looking at the body's class list
    useEffect(() => {
        const checkTheme = () => {
            setIsDarkTheme(document.body.classList.contains("dark-theme"));
        };

        // Initial check
        checkTheme();

        // MutationObserver to detect theme changes on body
        const observer = new MutationObserver(checkTheme);
        observer.observe(document.body, { attributes: true, attributeFilter: ["class"] });

        // Clean up observer on component unmount
        return () => observer.disconnect();
    }, []);
    //dark theme select end
    return (
        <>
            {loading ? <Loader /> : ""}

            <Modal id="create-HelpDesk-modal" show={showCreateHelpDeskModal} onHide={() => { handleCloseCreateHelpDeskModal(); clearAll() }} backdrop="static" centered size="lg">
                <Modal.Header closeButton className="gth-blue-light-bg">
                    <Modal.Title className="gth-modal-title">Raise New Ticket</Modal.Title>
                </Modal.Header>
                <form onSubmit={onSubmit}>
                    <Modal.Body className="pb-0">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <label className="form-label">Doer</label>
                                    <div className='custom-select-wrap'>
                                        <Select
                                            name='selectDoer'
                                            required
                                            options={User}
                                            value={helpValue.assign_to && User.length > 0 ? User.find(data => data.id == helpValue.assign_to) : ""}
                                            getOptionLabel={(option) => option.name}
                                            getOptionValue={(option) => option.id}
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#ddddff',
                                                    primary: '#6161ff',
                                                },
                                            })}
                                            onChange={(data) => setHelpValue({ ...helpValue, assign_to: data.id })}
                                            styles={{
                                                menu: (base) => ({
                                                    ...base,
                                                    backgroundColor: isDarkTheme ? "#06162f" : "#ffffff",
                                                    color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                }),
                                                singleValue: (base) => ({
                                                    ...base,
                                                    color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                }),
                                                option: (base, state) => ({
                                                    ...base,
                                                    backgroundColor: state.isFocused ? (isDarkTheme ? "#112a46" : "#ddddff") : "transparent",
                                                    color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                }),
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-lg-6">
                                <div className="form-group">
                                    <label className="form-label">Priority</label>
                                    <div className='custom-select-wrap'>
                                        <Select
                                            name='selectDoer'
                                            options={TaskPriority}
                                            required
                                            getOptionLabel={(option) => option.title}
                                            getOptionValue={(option) => option.id}
                                            isMulti={true}
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#ddddff',
                                                    primary: '#6161ff',
                                                },
                                            })}
                                            onChange={(data)=>setHelpValue({...helpValue,assign_to:data.id})}
                                            styles={{
                                  menu: (base) => ({
                                    ...base,
                                    backgroundColor: isDarkTheme ? "#06162f" : "#ffffff",
                                    color: isDarkTheme ? "#e0e0e0" : "#000000",
                                  }),
                                  singleValue: (base) => ({
                                    ...base,
                                    color: isDarkTheme ? "#e0e0e0" : "#000000",
                                  }),
                                  option: (base, state) => ({
                                    ...base,
                                    backgroundColor: state.isFocused ? (isDarkTheme ? "#112a46" : "#ddddff") : "transparent",
                                    color: isDarkTheme ? "#e0e0e0" : "#000000",
                                  }),
                                }}
                                        />
                                    </div>
                                </div>
                            </div> */}
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <label className="form-label">CC</label>
                                    <div className='custom-select-wrap'>
                                        <Select
                                            name='selectDoer'
                                            options={User.length > 0 ? User.filter(data => data.id != helpValue.assign_to) : []}
                                            getOptionLabel={(option) => option.name}
                                            getOptionValue={(option) => option.id}
                                            isMulti
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#ddddff',
                                                    primary: '#6161ff',
                                                },
                                            })}
                                            onChange={(data) => {
                                                const selectedValues = data ? data.map(option => option.id) : [];
                                                const MultiDoers = selectedValues.length > 0 ? JSON.stringify(selectedValues) : "";
                                                setHelpValue({ ...helpValue, cc: MultiDoers })
                                            }}
                                            styles={{
                                                menu: (base) => ({
                                                    ...base,
                                                    backgroundColor: isDarkTheme ? "#06162f" : "#ffffff",
                                                    color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                }),
                                                singleValue: (base) => ({
                                                    ...base,
                                                    color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                }),
                                                option: (base, state) => ({
                                                    ...base,
                                                    backgroundColor: state.isFocused ? (isDarkTheme ? "#112a46" : "#ddddff") : "transparent",
                                                    color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                }),
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <label className="form-label">Title</label>
                                    <input type="text" required className="form-control" value={helpValue.subject} placeholder="Enter title" onChange={(e) => setHelpValue({ ...helpValue, subject: e.target.value })} />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <label className="form-label">Message</label>
                                    <textarea className="form-control" rows="4" value={helpValue.description} placeholder="Enter your message" onChange={(e) => setHelpValue({ ...helpValue, description: e.target.value })}></textarea>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <label className="form-label">File</label>
                                    <input
                                        type="file"
                                        className="form-control"
                                        placeholder="Workflow Icon"
                                        onChange={handleTicketAttachment}
                                        accept=".pdf,.xls,.xlsx,.png,.jpg,.jpeg,.doc,.docx,"
                                    />
                                </div>
                            </div>
                            {/* <div className='col-12'>
                            <div className="form-group">
                                {ticketAttachments.length > 0 && (
                                    <div className="uploaded-imgs-wrap d-flex flex-wrap gap-3">
                                        {ticketAttachments.map((file, index) => (
                                            <div key={index} className="uploaded-img position-relative">
                                                <button
                                                    id="attachment-remove"
                                                    className="link-btn position-absolute top-0 end-0"
                                                    onClick={() => removeAttachment(index)}
                                                >
                                                    <i className="fi fi-sr-circle-xmark text-danger"></i>
                                                </button>
                                                <i className={`${getIconClass(file.type)} fileIcon`}></i>
                                                
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div> */}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type='submit' className="btn btn-exp-green" >
                            Create
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default CreateHelpTicket
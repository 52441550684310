
import React, { useEffect, useRef, useState, useCallback } from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DataTable from 'react-data-table-component';
import { Form, Modal, Offcanvas, OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';
import { HotTable } from '@handsontable/react';
import { registerAllModules } from 'handsontable/registry';
import 'handsontable/dist/handsontable.full.min.css';
import { PrivateAxios, PrivateAxiosFile, url } from '../../environment/AxiosInstance';
import { UserAuth } from '../auth/Auth';
import { exportExcel, exportPDF, printTable } from '../../environment/ExportData';
import { customStyles } from '../../environment/Datatable';
import Loader from '../../loader/Loader';
import { Link } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import Select from 'react-select'

import UserOffcanvas from '../../component/UserOffcanvas';
import { SuccessMessage } from '../../environment/ToastMessage';
import ViewHelpTicket from './ViewHelpTicket';
import HelpTicketMsg from './HelpTicketMsg';
import moment from 'moment';





function RaisedToMe() {
    const { Logout, User, userDetails } = UserAuth()
    const [loading, setLoading] = useState(false)
    const [raisedByMeTableData, setraisedByMeTableData] = useState([]);
    const [raisedByMeDataAll, setraisedByMeDataAll] = useState([]);

    //========================Get Api Call=============================
    const GetPending = () => {
        PrivateAxios.get('ticket/get-to-pending')
            .then((res) => {
                setraisedByMeTableData(res.data.data)
                setraisedByMeDataAll(res.data.data)
            }).catch((err) => {
                if (err.response.status == 401) {
                    Logout();
                }

            })
    }
    const GetResolve = () => {
        PrivateAxios.get('ticket/get-to-resolve')
            .then((res) => {
                setraisedByMeTableData(res.data.data)
                setraisedByMeDataAll(res.data.data)
            }).catch((err) => {
                if (err.response.status == 401) {
                    Logout();
                }

            })
    }
    const GetCancel = () => {
        PrivateAxios.get('ticket/get-to-close')
            .then((res) => {
                setraisedByMeTableData(res.data.data)
                setraisedByMeDataAll(res.data.data)
            }).catch((err) => {
                if (err.response.status == 401) {
                    Logout();
                }
            })
    }

    useEffect(() => {
        GetPending();
    }, [])

    //=====================Tab==============================
    const [activeTab, setActiveTab] = useState('pending');
    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
        if (tabId == "pending") {
            GetPending();
        }
        if (tabId == "resolve") {
            GetResolve();
        }
        if (tabId == "close") {
            GetCancel();
        }

    };

    function getUserNameById(userId) {
        const user = User.find(data => data.id == userId);
        return user ? user.name : "";
    }
    // data table start
    const raisedByMeColumns = [
        {
            name: 'Sl No.',
            selector: (row, index) => index + 1,
            width: "70px"
        },
        {
            name: 'Ticket No',
            selector: (row) => row.data.id,
            width: "130px",
        },
        {
            name: "Created Date",
            selector: (row) => moment(row.data.created_at).format('DD-MM-YYYY | hh:mm A'),
            sortable: true,
            width: "200px"
        },
        {
            name: "Resolve Date",
            selector: (row) => row.data.resolve_date ? moment(row.data.resolve_date).format('DD-MM-YYYY | hh:mm A') : "",
            omit: activeTab == "resolve" ? false : true,
            sortable: true,
            width: "200px"
        },
        {
            name: "Closed Date",
            selector: (row) => activeTab == "close" ? moment(row.data.closed_date).format('DD-MM-YYYY | hh:mm A') : "",
            omit: activeTab == "close" ? false : true,
            sortable: true,
            width: "200px"
        },
        {
            name: "Assignee",
            selector: (row) => getUserNameById(row.data.assigned_by),
            sortable: true,
            reorder: true,
            width: "220px",
            cell: (row) => (
                <button className="table-data-change-btn">
                    {row.data.assigned_by && User && User.map((item) => (
                        item.id == row.data.assigned_by ?
                            < div className="profile-wrap" key={item.id}>
                                <div className="exp-avtar bg-exp-yellow">
                                    {item.user_photo != null ?
                                        < img className="prof-img" src={url + item.user_photo} alt="user" /> :
                                        <span>{item.name != '' ? item.name.split(' ').length > 1 ? item.name.split(' ')[0].split('')[0] + item.name.split(' ')[1].split('')[0] : item.name.split('')[0] + item.name.split('')[1] : ""}</span>
                                    }
                                </div>
                                <div className="ps-2 profile-name-wrap text-truncate">
                                    <h5 className="profile-name text-nowrap text-truncate">{item.name}</h5>
                                </div>
                            </div> : ""
                    ))
                    }
                </button>

            ),
        },
        {
            name: "Doer",
            selector: (row) => getUserNameById(row.data.assign_to),
            sortable: true,
            width: "220px",
            cell: (row) => (
                <button className="table-data-change-btn">
                    {row.data.assign_to && User && User.map((item) => (
                        item.id == row.data.assign_to ?
                            < div className="profile-wrap" key={item.id}>
                                <div className="exp-avtar bg-exp-yellow">
                                    {item.user_photo != null ?
                                        < img className="prof-img" src={url + item.user_photo} alt="user" /> :
                                        <span>{item.name != '' ? item.name.split(' ').length > 1 ? item.name.split(' ')[0].split('')[0] + item.name.split(' ')[1].split('')[0] : item.name.split('')[0] + item.name.split('')[1] : ""}</span>
                                    }
                                </div>
                                <div className="ps-2 profile-name-wrap text-truncate">
                                    <h5 className="profile-name text-nowrap text-truncate">{item.name}</h5>
                                </div>
                            </div> : ""
                    ))
                    }
                </button>

            ),
        },
        {
            name: "Subject",
            selector: (row) => row.data.subject,
            sortable: true,
            width: "350px",
        },
        {
            name: "Status",
            selector: (row) => row.status == 0 ? "Pending" : row.status == 1 ? "Resolve" : row.status == 2 ? "Close" : "",
            sortable: true,
            width: "150px",
            cell: (row) => (
                <>
                    {row.data.status == 0 ?
                        <div className="status-item todo">
                            <i className="far fa-dot-circle me-2"></i>
                            <span>Pending</span>
                        </div> :
                        row.data.status == 1 ?
                            <div className="status-item todo">
                                <i className="fas fa-check-circle me-2"></i>
                                <span>Resolve</span>
                            </div> : row.data.status == 2 ?
                                <div className="status-item todo">
                                    <i className="far fa-times-circle me-2"></i>
                                    <span>Close</span>
                                </div> : ""
                    }
                </>
            ),
        },
        {
            name: "Action",
            width: "170px",
            selector: (row) => null,
            cell: (row) => (
                <div className="d-flex">
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip>
                                View
                            </Tooltip>
                        }
                    >
                        <button className="me-1 table-action-btn" onClick={() => handleShowViewHelpDeskModal(row.data)}>
                            <i class="fas fa-eye"></i>
                        </button>
                    </OverlayTrigger>
                    {row.data.status == 0 ?
                        <>
                            {row.data.assign_to == userDetails.id ?
                                <>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip>
                                                Switch Doer
                                            </Tooltip>
                                        }
                                    >
                                        <button className="me-1 table-action-btn" onClick={() => handleShowDoer(row.data.id)}>
                                            <i class="fas fa-exchange-alt"></i>
                                        </button>
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip>
                                                Done
                                            </Tooltip>
                                        }
                                    >
                                        <button className="me-1 table-action-btn" onClick={() => taskDoneModalShow(row.data)}>
                                            <i className="fas fa-check"></i>
                                        </button>
                                    </OverlayTrigger>
                                </> : ""
                            }
                        </> : ""
                    }
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip>
                                Chat
                            </Tooltip>
                        }
                    >
                        <button className="me-1 table-action-btn" onClick={() => handleShowChatWrap(row.data)}>
                            <i class="fas fa-comment-dots"></i>
                        </button>
                    </OverlayTrigger>
                </div>
            ),
        },
    ];



    // chat cnavas
    //=========================chat cnavas===========================
    const [showChatWrap, setShowChatWrap] = useState(false);
    const [showChatData, setShowChatData] = useState('');
    const handleCloseChatWrap = () => {
        setShowChatWrap(false);
    }
    const handleShowChatWrap = (data) => {
        setShowChatWrap(true);
        setShowChatData(data)
    }
    // for Doer selection    

    //task Stop modal
    const [stopShow, setStopModalShow] = useState(false);
    const stopModalClose = () => setStopModalShow(false);
    const stopModalShow = () => setStopModalShow(true);

    //========================View Help Desk Modal Start=======================
    const [showViewHelpDeskModal, setShowViewHelpDeskModal] = useState(false);
    const [showViewHelpData, setShowViewHelpData] = useState('');
    const handleCloseViewHelpDeskModal = () => {
        setShowViewHelpDeskModal(false);
        setShowViewHelpData('')
    }
    const handleShowViewHelpDeskModal = (data) => {
        setShowViewHelpDeskModal(true);
        setShowViewHelpData(data)
    }

    //========================Select Doer=======================================
    const [showDoer, setShowDoer] = useState(false);
    const [showTicketId, setTicketId] = useState('');
    const handleCloseDoer = () => {
        setShowDoer(false);
        setTicketId('')
    }
    const handleShowDoer = (id) => {
        setShowDoer(true);
        setTicketId(id)
    }

    const selectAssingee = (id) => {
        PrivateAxios.put(`ticket/switch-doer/${showTicketId}`, { user_id: id.id })
            .then((res) => {
                GetPending();
                console.log(res.data);

                SuccessMessage(res.data.msg)
                handleCloseDoer();

            }).catch((err) => {
                if (err.response.status == 401) {
                    Logout();
                }
            })
    }

    //=================Done============================
    const [taskDoneShow, setTaskDoneShow] = useState(false);
    const [taskDoneId, setTaskDoneId] = useState('');
    const [taskDoneData, setTaskDoneData] = useState('');
    const [taskDoneInputData, setTaskDoneInputData] = useState({
        "remark": "",
        "file": ""
    });
    const taskDoneModalClose = () => {
        setTaskDoneShow(false);
        setTaskDoneId('')
    }
    const taskDoneModalShow = (id) => {
        setTaskDoneShow(true);
        setTaskDoneId(id.id)
        setTaskDoneData(id)
    }

    const DoneTicket = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('remark', taskDoneInputData.remark);
        formData.append('file', taskDoneInputData.file);
        formData.append('id', taskDoneId);

        PrivateAxiosFile.post("ticket/done", formData)
            .then((res) => {
                GetPending();
                SuccessMessage(res.data.msg)
                taskDoneModalClose();
            }).catch((err) => {
                if (err.response.status == 401) {
                    Logout();
                }
            })
    }

    // exportExcel
    const generatePdf = () => {
        exportPDF(raisedByMeColumns, raisedByMeTableData, "Ticket")
    }
    const generateExcel = () => {
        exportExcel(raisedByMeColumns, raisedByMeTableData, "Ticket")
    }
    const generatePrint = () => {
        printTable(raisedByMeColumns, raisedByMeTableData, "Ticket")
    }

    const handleKeyUpSearch = (e) => {
        const filteredItems = raisedByMeDataAll.filter((item) => {
            return item && item.data.subject && item.data.subject.toLowerCase().replace(/\s+/g, '').includes(e.target.value.toLowerCase().replace(/\s+/g, ''))
        }
        );
        setraisedByMeTableData(filteredItems);
    }


    //====================Multi Search==========================
    //======================================================================
    const [open, setOpen] = useState(false);
    const [searchData, setSearchData] = useState({
        "id": "",
        "assigned_by": "",
        "subject": "",
        "assign_to": "",
    })
    const [task, setTask] = useState({
        startData: "",
        endDate: ""
    })
    const SearchDataButton = (e) => {
        e.preventDefault();
        const filterData = raisedByMeDataAll.filter((item) => {
            return Object.keys(searchData).every(key => {
                const searchValue = searchData[key];
                const itemValue = item.data[key];

                if (!searchValue) return true;
                if (itemValue === undefined || itemValue === null) return false;
                if (typeof itemValue === 'string') {
                    return itemValue.toLowerCase().replace(/\s+/g, '').includes(searchValue.toLowerCase().replace(/\s+/g, ''));
                }

                if (typeof itemValue === 'number') {
                    return itemValue.toString().replace(/\s+/g, '').includes(searchValue.toString().replace(/\s+/g, ''));
                }
                return false;
            }) && (
                    (!task.startData || new Date(item.data.created_at) >= new Date(task.startData)) &&
                    (!task.endDate || new Date(item.data.created_at) <= new Date(task.endDate))
                );
        })
        setraisedByMeTableData(filterData);
    }

    const ClearAll = () => {
        setSearchData({
            "name": "",
            "assign_to": "",
            "task_priority_id": "",
            "task_priority_id": "",
        });
        setTask({
            startData: "",
            endDate: ""
        })
        setraisedByMeTableData(raisedByMeDataAll);
    }

    //dark theme select
    const [isDarkTheme, setIsDarkTheme] = useState(false);

    // Check for the theme by looking at the body's class list
    useEffect(() => {
        const checkTheme = () => {
            setIsDarkTheme(document.body.classList.contains("dark-theme"));
        };

        // Initial check
        checkTheme();

        // MutationObserver to detect theme changes on body
        const observer = new MutationObserver(checkTheme);
        observer.observe(document.body, { attributes: true, attributeFilter: ["class"] });

        // Clean up observer on component unmount
        return () => observer.disconnect();
    }, []);
    //dark theme select end

    return (

        <React.Fragment>
            {loading ? <Loader /> :
                <>
                    <div className="gthh-controller-bar">
                        <ul className="gth-controller-view-block">
                            <li>
                                <Link className="gth-controller-view-item active" to="/raised-to-me">
                                    <i className="fas fa-level-down-alt me-2" />
                                    Raised to Me
                                </Link>
                            </li>
                            <li>
                                <Link className="gth-controller-view-item " to="/raised-by-me">
                                    <i className="fas fa-level-up-alt me-2" />
                                    Raised by Me
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className='bg-white border-bottom mb-4'>
                        <div className="d-flex flex-wrap px-4 py-2 justify-content-between align-items-center">
                            <ul className="nav nav-tabs gth-rounded-tab-2" id="systemControllerFilterTab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button
                                        className={`nav-link ${activeTab === 'pending' ? 'active' : ''}`}
                                        onClick={() => handleTabClick('pending')}
                                        type="button"
                                        role="tab"
                                        aria-controls="pending"
                                        aria-selected={activeTab === 'pending'}
                                    >
                                        <span className="btn-todo"><i className="far fa-dot-circle me-1"></i>Pending</span>
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button
                                        className={`nav-link ${activeTab === 'resolve' ? 'active' : ''}`}
                                        onClick={() => handleTabClick('resolve')}
                                        type="button"
                                        role="tab"
                                        aria-controls="resolve"
                                        aria-selected={activeTab === 'resolve'}
                                    >
                                        <span className="btn-completed"><i className="fas fa-check-circle me-1"></i>Resolve</span>
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button
                                        className={`nav-link ${activeTab === 'close' ? 'active' : ''}`}
                                        onClick={() => handleTabClick('close')}
                                        type="button"
                                        role="tab"
                                        aria-controls="close"
                                        aria-selected={activeTab === 'close'}
                                    >
                                        <span className="btn-closeStop"><i className="far fa-times-circle me-1"></i>Cancel</span>
                                    </button>
                                </li>
                            </ul>
                            <div className='d-flex my-1 ms-auto'>
                                <button className="btn btn-exp-info btn-sm" onClick={() => { setOpen(!open); ClearAll() }} data-bs-toggle="collapse" href="#contentId" aria-expanded="false" aria-controls="contentId" >
                                    <i className="fas fa-filter me-2"></i>Filter
                                </button>
                            </div>
                        </div>
                        <div className='w-100'>
                            <div className="collapse px-4 py-2" id="contentId">
                                <form id='search-form'>
                                    <div className='card'>
                                        <div className='card-header'>
                                            <h5 className='card-title font-weight-medium'>
                                                Search Help Ticket
                                            </h5>
                                        </div>

                                        <div className='card-body pb-1'>
                                            <div className='row'>
                                                <div className='col-lg-3 col-md-4 col-sm-6 col-12'>
                                                    <div className='form-group'>
                                                        <label className='form-label'>Assignee</label>
                                                        <div className='custom-select-wrap'>
                                                            <Select
                                                                name='selectDoer'
                                                                options={User}
                                                                getOptionLabel={(option) => option.name}
                                                                getOptionValue={(option) => option.id}
                                                                value={User.find(data => data.id == searchData.assigned_by) || ''}
                                                                onChange={(e) => { setSearchData({ ...searchData, assigned_by: e.id }) }}
                                                                theme={(theme) => ({
                                                                    ...theme,
                                                                    colors: {
                                                                        ...theme.colors,
                                                                        primary25: '#ddddff',
                                                                        primary: '#6161ff',
                                                                    },
                                                                })}
                                                                styles={{
                                                                    menu: (base) => ({
                                                                        ...base,
                                                                        backgroundColor: isDarkTheme ? "#06162f" : "#ffffff",
                                                                        color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                                    }),
                                                                    singleValue: (base) => ({
                                                                        ...base,
                                                                        color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                                    }),
                                                                    option: (base, state) => ({
                                                                        ...base,
                                                                        backgroundColor: state.isFocused ? (isDarkTheme ? "#112a46" : "#ddddff") : "transparent",
                                                                        color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                                    }),
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-lg-3 col-md-4 col-sm-6 col-12'>
                                                    <div className='form-group'>
                                                        <label className='form-label'>Doer</label>
                                                        <div className='custom-select-wrap'>
                                                            <Select
                                                                name='selectDoer'
                                                                options={User}
                                                                getOptionLabel={(option) => option.name}
                                                                getOptionValue={(option) => option.id}
                                                                value={User.find(data => data.id == searchData.assign_to) || ''}
                                                                onChange={(e) => { setSearchData({ ...searchData, assign_to: e.id }) }}
                                                                theme={(theme) => ({
                                                                    ...theme,
                                                                    colors: {
                                                                        ...theme.colors,
                                                                        primary25: '#ddddff',
                                                                        primary: '#6161ff',
                                                                    },
                                                                })}
                                                                styles={{
                                                                    menu: (base) => ({
                                                                        ...base,
                                                                        backgroundColor: isDarkTheme ? "#06162f" : "#ffffff",
                                                                        color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                                    }),
                                                                    singleValue: (base) => ({
                                                                        ...base,
                                                                        color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                                    }),
                                                                    option: (base, state) => ({
                                                                        ...base,
                                                                        backgroundColor: state.isFocused ? (isDarkTheme ? "#112a46" : "#ddddff") : "transparent",
                                                                        color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                                    }),
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-lg-3 col-md-4 col-sm-6 col-12'>
                                                    <div className='form-group'>
                                                        <label className='form-label'>Ticket Number</label>
                                                        <input
                                                            type='text'
                                                            value={searchData.id}
                                                            className='form-control'
                                                            placeholder='Enter Ticket Number'
                                                            onChange={(e) => setSearchData({ ...searchData, id: e.target.value })}
                                                        />
                                                    </div>
                                                </div>

                                                <div className='col-lg-3 col-md-4 col-sm-6 col-12'>
                                                    <div className='form-group'>
                                                        <label className='form-label'>Subject</label>
                                                        <input
                                                            type='text'
                                                            value={searchData.subject}
                                                            className='form-control'
                                                            placeholder='Enter Subject'
                                                            onChange={(e) => setSearchData({ ...searchData, subject: e.target.value })}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-lg-3 col-md-4 col-sm-6 col-12'>
                                                    <div className='form-group'>
                                                        <label className='form-label'>From Date</label>
                                                        <div className="exp-datepicker-cont">
                                                            <span className="cal-icon"><i className="fas fa-calendar-alt" /></span>
                                                            <DatePicker
                                                                selected={task.startData} onChange={(date) => setTask({ ...task, startData: date })}
                                                                dateFormat="dd/MM/YYYY"
                                                                placeholderText='Select Date'
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-lg-3 col-md-4 col-sm-6 col-12'>
                                                    <div className='form-group'>
                                                        <label className='form-label'>To Date</label>
                                                        <div className="exp-datepicker-cont">
                                                            <span className="cal-icon"><i className="fas fa-calendar-alt" /></span>
                                                            <DatePicker
                                                                selected={task.endDate} onChange={(date) => setTask({ ...task, endDate: date })}
                                                                dateFormat="dd/MM/YYYY"
                                                                placeholderText='Select Date'
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-footer d-flex justify-content-end">
                                            <button className="btn btn-exp-green" onClick={SearchDataButton}  >Search</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className='gth-dashboard-v2-process-wrap'>
                        <div className="tab-content pt-0">
                            <div className={`tab-pane ${activeTab === 'pending' ? 'active' : ''}`} id="pending" role="tabpanel">
                                <div className='card'>
                                    <div className='card-body p-0'>
                                        {/* <div div className="w-100">
                                        <div className="card bg-warning-light mb-0">
                                            <div className="card-body">
                                                <div className="exp-no-data-found text-exp-red">
                                                    <img className="task-img mb-3" src={process.env.PUBLIC_URL + 'assets/images/search-no-record-found.webp'} alt="No task" />
                                                    <h6>No Record Found</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>  */}
                                        <>
                                            <div className="table-view" >
                                                <div className='d-flex justify-content-between flex-wrap align-items-center pt-3 px-3'>
                                                    <div className='table-button-group mb-3'>
                                                        <button className='btn table-export-btn' onClick={generateExcel} >
                                                            <i className="fas fa-file-csv"></i>
                                                        </button>
                                                        <button className='btn table-export-btn' onClick={generatePdf}>
                                                            <i className="fas fa-file-pdf"></i>
                                                        </button>
                                                        <button className='btn table-export-btn' onClick={generatePrint}>
                                                            <i className="fas fa-print"></i>
                                                        </button>
                                                    </div>
                                                    <div className='d-flex align-items-center mb-3 ms-auto'>
                                                        <label className='mr-2 mb-0'>Search: </label>
                                                        <input type='text' onChange={handleKeyUpSearch} placeholder='Type here...' className='form-control form-control-sm' />
                                                    </div>
                                                </div>

                                                <DataTable
                                                    columns={raisedByMeColumns}
                                                    data={raisedByMeTableData}
                                                    //pagination={[5, 10, 25, 50]}
                                                    pagination={false}
                                                    theme="solarized"
                                                    striped
                                                    className='custom-table-wrap workflow-table-striped'
                                                />
                                            </div>
                                        </>

                                    </div>
                                </div>
                            </div>
                            <div className={`tab-pane ${activeTab === 'resolve' ? 'active' : ''}`} id="resolve" role="tabpanel">
                                <div className='card'>
                                    <div className='card-body p-0'>
                                        {/* <div div className="w-100">
                                        <div className="card bg-warning-light mb-0">
                                            <div className="card-body">
                                                <div className="exp-no-data-found text-exp-red">
                                                    <img className="task-img mb-3" src={process.env.PUBLIC_URL + 'assets/images/search-no-record-found.webp'} alt="No task" />
                                                    <h6>No Record Found</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>  */}
                                        <>
                                            <div className="table-view" >
                                                <div className='d-flex justify-content-between flex-wrap align-items-center pt-3 px-3'>
                                                    <div className='table-button-group mb-3'>
                                                        <button className='btn table-export-btn' onClick={generateExcel} >
                                                            <i className="fas fa-file-csv"></i>
                                                        </button>
                                                        <button className='btn table-export-btn' onClick={generatePdf}>
                                                            <i className="fas fa-file-pdf"></i>
                                                        </button>
                                                        <button className='btn table-export-btn' onClick={generatePrint}>
                                                            <i className="fas fa-print"></i>
                                                        </button>
                                                    </div>
                                                    <div className='d-flex align-items-center mb-3 ms-auto'>
                                                        <label className='mr-2 mb-0'>Search: </label>
                                                        <input type='text' placeholder='Type here...' className='form-control form-control-sm' />
                                                    </div>
                                                </div>

                                                <DataTable
                                                    columns={raisedByMeColumns}
                                                    data={raisedByMeTableData}
                                                    //pagination={[5, 10, 25, 50]}
                                                    pagination={false}
                                                    theme="solarized"
                                                    striped
                                                    className='custom-table-wrap workflow-table-striped'
                                                />
                                            </div>
                                        </>

                                    </div>
                                </div>
                            </div>
                            <div className={`tab-pane ${activeTab === 'close' ? 'active' : ''}`} id="close" role="tabpanel">
                                <div className='card'>
                                    <div className='card-body p-0'>
                                        {/* <div div className="w-100">
                                        <div className="card bg-warning-light mb-0">
                                            <div className="card-body">
                                                <div className="exp-no-data-found text-exp-red">
                                                    <img className="task-img mb-3" src={process.env.PUBLIC_URL + 'assets/images/search-no-record-found.webp'} alt="No task" />
                                                    <h6>No Record Found</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>  */}
                                        <>
                                            <div className="table-view" >
                                                <div className='d-flex justify-content-between flex-wrap align-items-center pt-3 px-3'>
                                                    <div className='table-button-group mb-3'>
                                                        <button className='btn table-export-btn' onClick={generateExcel} >
                                                            <i className="fas fa-file-csv"></i>
                                                        </button>
                                                        <button className='btn table-export-btn' onClick={generatePdf}>
                                                            <i className="fas fa-file-pdf"></i>
                                                        </button>
                                                        <button className='btn table-export-btn' onClick={generatePrint}>
                                                            <i className="fas fa-print"></i>
                                                        </button>
                                                    </div>
                                                    <div className='d-flex align-items-center mb-3 ms-auto'>
                                                        <label className='mr-2 mb-0'>Search: </label>
                                                        <input type='text' placeholder='Type here...' className='form-control form-control-sm' />
                                                    </div>
                                                </div>

                                                <DataTable
                                                    columns={raisedByMeColumns}
                                                    data={raisedByMeTableData}
                                                    //pagination={[5, 10, 25, 50]}
                                                    pagination={false}
                                                    theme="solarized"
                                                    striped
                                                    className='custom-table-wrap workflow-table-striped'
                                                />
                                            </div>
                                        </>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
            <>


                {/* chat box */}
                <HelpTicketMsg showChatWrap={showChatWrap} handleCloseChatWrap={handleCloseChatWrap} showChatData={showChatData} activeTab={activeTab} GetCancel={GetCancel} GetResolve={GetResolve} GetPending={GetPending} />

                {/* chat box end */}
                {/* for doer */}
                <UserOffcanvas show={showDoer} handleClose={handleCloseDoer} title={"Select Doer"} onSelectUser={selectAssingee} />
                {/* <form>
                    <Offcanvas id="ticket-doer-change" className="assignee-canvas" show={showDoer} onHide={handleCloseDoer} placement="end">
                        <Offcanvas.Header closeButton className="d-block">
                            <Offcanvas.Title>Change Doer</Offcanvas.Title>
                            <div className="search-assignee">
                                <span className="search-assignee-icon">
                                    <i class="fas fa-search"></i>
                                </span>
                                <input type="text" className="form-control" placeholder="Search or enter email.." />
                            </div>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <div id="selectAssignee" className="assignee-wrapper assignee-wrapper-body">
                                <div className="user-list-wrap">
                                    <div className="user-item">
                                        <div className="profile-wrap">
                                            <div className="exp-avtar">
                                                <i class="fas fa-user user-icon"></i>
                                            </div>
                                            <div className="ps-2 profile-name-wrap text-truncate">
                                                <h5 className="profile-name text-nowrap text-truncate">Sujit Paul</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="user-item">
                                        <div className="profile-wrap">
                                            <div className="exp-avtar bg-exp-green">
                                                <span>JP</span>
                                            </div>
                                            <div className="ps-2 profile-name-wrap text-truncate">
                                                <h5 className="profile-name text-nowrap text-truncate">Jhon Parker</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="user-item">
                                        <div className="profile-wrap">
                                            <div className="exp-avtar">
                                                <img className="prof-img" src={process.env.PUBLIC_URL + 'assets/images/users/1694671741_1517351722263.jpg'} alt="user" />
                                            </div>
                                            <div className="ps-2 profile-name-wrap text-truncate">
                                                <h5 className="profile-name text-nowrap text-truncate">Subhadeep Chowdhury</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="user-item">
                                        <div className="profile-wrap">
                                            <div className="exp-avtar">
                                                <i class="fas fa-user user-icon"></i>
                                            </div>
                                            <div className="ps-2 profile-name-wrap text-truncate">
                                                <h5 className="profile-name text-nowrap text-truncate">Sujit Paul</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="user-item">
                                        <div className="profile-wrap">
                                            <div className="exp-avtar bg-exp-blue">
                                                <span>MS</span>
                                            </div>
                                            <div className="ps-2 profile-name-wrap text-truncate">
                                                <h5 className="profile-name text-nowrap text-truncate">Moumita Shome</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="user-item">
                                        <div className="profile-wrap">
                                            <div className="exp-avtar">
                                                <img className="prof-img" src={process.env.PUBLIC_URL + 'assets/images/users/1694671598_002.png'} alt="user" />
                                            </div>
                                            <div className="ps-2 profile-name-wrap text-truncate">
                                                <h5 className="profile-name text-nowrap text-truncate">Gopal Mukherjee</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Offcanvas.Body>
                    </Offcanvas>
                </form> */}
                {/* for doer end */}
                {/* Task Done modal start */}
                <Modal id="ticketDone"
                    show={taskDoneShow}
                    onHide={taskDoneModalClose}
                    backdrop="static"
                    keyboard={false}
                    centered
                >
                    <Modal.Header closeButton className="gth-blue-light-bg">
                        <Modal.Title className="gth-modal-title">Complete Ticket No: <span>{taskDoneData && taskDoneData.id}</span> </Modal.Title>
                    </Modal.Header>
                    <form onSubmit={DoneTicket}>
                        <Modal.Body className='pb-1'>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className="form-group">
                                        <label className="form-label">Doer : </label>
                                        <div className="">
                                            {taskDoneData && User.map((item) => (
                                                item.id == taskDoneData.assign_to ?
                                                    < div className="profile-wrap" key={item.id}>
                                                        <div className="exp-avtar bg-exp-yellow">
                                                            {item.user_photo != null ?
                                                                < img className="prof-img" src={url + item.user_photo} alt="user" /> :
                                                                <span>{item.name != '' ? item.name.split(' ').length > 1 ? item.name.split(' ')[0].split('')[0] + item.name.split(' ')[1].split('')[0] : item.name.split('')[0] + item.name.split('')[1] : ""}</span>
                                                            }
                                                        </div>
                                                        <div className="ps-2 profile-name-wrap text-truncate">
                                                            <h5 className="profile-name text-nowrap text-truncate">{item.name}</h5>
                                                        </div>
                                                    </div> : ""
                                            ))
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className='col-12'>
                                    <div className="form-group">
                                        <label className="form-label">Remarks : </label>
                                        <textarea required className="form-control" name="remarks" rows="3" onChange={(e) => setTaskDoneInputData({ ...taskDoneInputData, remark: e.target.value })}></textarea>
                                    </div>
                                </div>
                                <div className='col-12'>
                                    <div className="form-group">
                                        <label className="form-label">File </label>
                                        <div className="custom-file-input">
                                            <input type="file" name='file' className='form-control' onChange={(e) => setTaskDoneInputData({ ...taskDoneInputData, file: e.target.files[0] })} />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <button type='submit' className='btn btn-exp-green'>
                                Confirm
                            </button>
                        </Modal.Footer>
                    </form>
                </Modal>
                {/* Task Done modal end */}
                {/* Cancel Ticket modal start */}
                <form>
                    <Modal id="ticket-stop-modal"
                        show={stopShow}
                        onHide={stopModalClose}
                        backdrop="static"
                        keyboard={false}
                        centered
                    >
                        <Modal.Header closeButton className="gth-blue-light-bg">
                            <Modal.Title className="gth-modal-title">Cancel Ticket ID: <span>ID00001</span></Modal.Title>
                        </Modal.Header>
                        <Modal.Body className='pb-1'>
                            <div className='row'>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label htmlFor="remarks" className="form-label">Remarks</label>
                                        <textarea className="form-control" name="remarks" rows={3} defaultValue={""} />
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className='btn btn-exp-green'>
                                Confirm
                            </button>
                        </Modal.Footer>
                    </Modal>
                </form>
                {/* Cancel Ticket modal end */}

                {/* View New Help Desk Modal */}
                <ViewHelpTicket showViewHelpDeskModal={showViewHelpDeskModal} data={showViewHelpData} handleCloseViewHelpDeskModal={handleCloseViewHelpDeskModal} />
                {/* View New Help Desk Modal End*/}
            </>

        </React.Fragment >

    )
}

export default RaisedToMe;